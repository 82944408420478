import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../../UserContext";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

function CreateInterestAdmin() {

    const{auth,user} = React.useContext(UserContext)
    const [userid, setUserid] = React.useState('');

    const [userInfo, setUserInfo] = React.useState([]);
    
    const [getNewAmmount, setNewAmount] = React.useState(0);
    const [mainPercentage, setMainPercentage] = React.useState(0);
    const [metaid, setMetaid] = React.useState(0);
    
    

    React.useEffect( () => {

        if (auth != null) {
            if (!auth) {
            window.location.href="/login"
            }else{
                setUserid(user.user.userid);
                getAllPaidReferrals(user.user.userid)
            }
        }
    }, [auth])

        const getAllPaidReferrals = async(userid) => {
            const userObj = {
                userid: userid
            }
            await axios.post(process.env.REACT_APP_BASE + "api/get-all-interest-users",userObj).then(async res =>{

                console.log(res.data)
                
                if(res.data.status === 200)
                {
                    setUserInfo(res.data.allocatedData)
                    setMainPercentage(res.data.mainPercentage)
                    setMetaid(res.data.id)
                }else{
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });
                }
            
              });
        }


        const changeNewPercentage = async(newAmount, id, key) => {
            // console.log("the data we received: " + userInfo[key].new_percentage, newAmount)
            // userInfo[key].new_amount = (userInfo[key].amount) * newAmount / 100;
            // userInfo[key].new_percentage = parseFloat(newAmount);
            // setUserInfo(userInfo)
   

            // 1. Make a shallow copy of the array
            let temp_state = [...userInfo];
            
            // 2. Make a shallow copy of the element you want to mutate
            let temp_element = { ...temp_state[key] };

            if(newAmount > mainPercentage)
            {
                toast.error('Percentage More Than Allocated', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                return false;
            }
            
            // 3. Update the property you're interested in
            temp_element.new_percentage = parseFloat(newAmount);
            temp_element.new_amount = (temp_element.amount) * newAmount / 100;

            // Now we need to find out what the referral amounts will be
            temp_element.referral_percentage = parseFloat(mainPercentage) - parseFloat(newAmount)

            // Lets find out the amount they will receive
            temp_element.referral_amount = (temp_element.amount * temp_element.referral_percentage) / 100;
            
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            temp_state[key] = temp_element;

            // 5. Set the state to our new copy
            setUserInfo( temp_state );

            return true;

        }

        const changeCompleteStatus = async(newAmount, id, key) => {

            if(newAmount > mainPercentage)
            {
                toast.error('Percentage More Than Allocated', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                return false;
            }

            // 1. Make a shallow copy of the array
            let temp_state = [...userInfo];
            
            // 2. Make a shallow copy of the element you want to mutate
            let temp_element = { ...temp_state[key] };
                        
            // 3. Update the property you're interested in
            temp_element.status = '1';
                        
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            temp_state[key] = temp_element;
            
            // 5. Set the state to our new copy
            setUserInfo( temp_state );
            
            return true;
        }

        const sendPaymentsThrough = async() => {

            var userObj = {
                paymentInfo: userInfo,
                metaid: metaid
            }
            
            await axios.post(process.env.REACT_APP_BASE + "api/createNewPaymentRequest",userObj).then(async res =>{
                if(res.data.status === 200)
                {
                    toast.success("Thank you, admin will approve shortly", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });
                        setTimeout(() => {window.location.href="/dashboard"; }, 3000);
                    
                }
            });
        }

  return (
    <React.Fragment>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Profile" breadcrumbItem="Profile" />

                <Row>
                    <h1>Percentage Allocated Is - {mainPercentage}%</h1>
                    {(typeof userInfo !== undefined && userInfo.length > 0) ?
                        userInfo.map((info, key) => (

                            <Col xl="12" key={key}>
                                <Card className={info.status === '0' ? 'bg-danger' : 'bg-success-light'}>
                                    <CardBody>
                                    <div className="mt-4 mb-3">
                                        <Row>
                                            <Col md="4">
                                                <label>Userid: {info.userid} ({info.planid})</label>
                                            </Col>

                                            <Col md="4">
                                                <label>Inv Amount: {info.amount}</label>
                                            </Col>

                                            <Col md="4">
                                                <label>New Percentage: </label>
                                                <input 
                                                    type="number" 
                                                    name="percentage" 
                                                    min={0}
                                                    max={mainPercentage}
                                                    value={info.new_percentage}
                                                    className="form-control"
                                                    onChange={(e) => {changeNewPercentage(e.target.value, info.id, key)}} />
                                            </Col>

                                            <Col md="4">
                                                <label>Comms Amount: </label>
                                                <input 
                                                    type="number" 
                                                    name="amount" 
                                                    disabled
                                                    value={info.new_amount}
                                                    className="form-control"  />
                                            </Col>

                                            <Col md="4">
                                                <label>Referral Comms: </label>
                                                <input 
                                                    type="number" 
                                                    name="amount" 
                                                    disabled
                                                    value={info.referral_amount}
                                                    className="form-control"  />
                                            </Col>

                                            <Col md="4">
                                                <label>Referral percentage: </label>
                                                <input 
                                                    type="number" 
                                                    name="amount" 
                                                    disabled
                                                    value={info.referral_percentage}
                                                    className="form-control"  />
                                            </Col>
                                            {info.status === '0' ?
                                            <button className="btn btn-success mt-4" onClick={(e) => {changeCompleteStatus(info.new_percentage, info.id, key)}} >Done</button>
                                            : ''}

                                        </Row>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    : ''}
                    {(typeof userInfo !== undefined && userInfo.length > 0) ?
                    <button onClick={sendPaymentsThrough} className="btn btn-primary mt-4">Send Now</button>
                    : ''}
                </Row>
            
            </Container>
        </div>
    </React.Fragment>        
  )
}

export default CreateInterestAdmin