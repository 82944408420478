import React from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"

const MonthlyEarning = ({monthlyDataTotal}) => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Monthly Earning</CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">This month</p>
              <h3>$ {monthlyDataTotal}</h3>
         
              <div className="mt-4">
                <Link
                  to="./referral-histsory"
                  className="btn btn-primary  btn-sm"
                >
                  View More <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
            <Col sm="6">
   
            </Col>
          </Row>

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
