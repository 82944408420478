import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import AllEarnings from "./AllEarnings";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../UserContext";
import axios from 'axios'
import SuperAdminInfo from "components/Dashboard/SuperAdminInfo";


const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title="Dashboard | AlphaX Green (Pty) LTD"; 


  const{auth,user} = React.useContext(UserContext)
  const[userid, setUserid] =  React.useState('');
  const[connectedUser, setConnectedUser] =  React.useState('');
  const[role, setRole] =  React.useState('');
  const[username, setUsername] =  React.useState('');
  const[adminInfo, setAdminInfo] =  React.useState('');

  const[activeUsers, setActiveUsers] =  React.useState(0);
  const[allUsers, setAllUsers] =  React.useState(0);
  const[isSuperAdmin, setIsSuperAdmin] =  React.useState(false);
  const[monthlyDataTotal, setMonthlyDataTotal] =  React.useState(0);

  const[adminLink, setAdminLink] =  React.useState('');


  React.useEffect( () => {
  
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        setUserid(user.user.userid)
        setUsername(user.user.name)
        setRole(user.user.u_role)
        // getAdminTransactions()

         getAllCompanyInfo(user.user.company_id)
        if(user.user.u_role === 'super')
        {
          setIsSuperAdmin(true)
        
        }
        else{
          if(user.user.u_role === 'admin')
          {
            getMonthlyData(user.user)
          }
          if(user.user.u_role === 'agent')
          {
            setConnectedUser(user.user.connected_user)
          }
        }
        setAdminInfo(user.user)
        getAllTasks(user.user.userid)
        getAdminUserInfo(user.user)

        
      }
    }
  }, [auth])



  const getAdminUserInfo = async (info) => {

    const userData = {
      info: info,
    }


    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-children-data`,userData).then((res)=>{
      console.log("the result for active users is ", res.data)
      if(res.data.status == 200)
      {
        setAllUsers(res.data.allNumber)
        setActiveUsers(res.data.activeNumber)
      }

    })
  }

  const getMonthlyData = async (info) => {
    const userData = {
      info: info,
    }


    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-monthly-data`,userData).then((res)=>{
      console.log(res.data)

      if(res.data.status == 200)
      {
        
        setMonthlyDataTotal(res.data.total)
      }

    })
  }

  
  const[totalInterest, setTotalInterest] =  React.useState(0);
  const[product1, setProduct1] =  React.useState(0);
  const[product2, setProduct2] =  React.useState(0);
  const[product3, setProduct3] =  React.useState(0);
  const[newdate, setnewdate] =  React.useState(0);

  

  const[excahnge_fee_zar, setExcahnge_fee_zar] =  React.useState(0);
  const[excahnge_fee_usdt, setExcahnge_fee_usdt] =  React.useState(0);
  const[excahnge_fee_btc, setExcahnge_fee_btc] =  React.useState(0);

  
  const[futureInterest, setFutureInterest] =  React.useState(0);

  
  const[futureTrade, setFutureTrade] =  React.useState(0);
  const[totalOpen, setTotalOpen] =  React.useState(0);
  const[totalHoldingTank, setTotalHoldingTank] =  React.useState(0);

  const [allTasks, setAllTasks] = useState([])


  const getSuperAdminData = async () => {
     const userData = {}


    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-superadmin-monthly-data`,userData).then((res)=>{

      if(res.data.status == 200)
      {
        console.log("data is ", res.data)
        setTotalInterest(res.data.totalInterest)
        setProduct1(res.data.product1)
        setProduct2(res.data.product2)
        setProduct3(res.data.product3)
        setnewdate(res.data.newdate)
      }

    })

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-exchange-fee-total`,{}).then((res)=>{

      if(res.data.status == 200)
      {
        console.log("data is ", res.data)
        setExcahnge_fee_zar(res.data.zar)
        setExcahnge_fee_usdt(res.data.usdt)
        setExcahnge_fee_btc(res.data.btc)
      }

    })

    
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-future-referrals`,{}).then((res)=>{
      console.log("New referral data is ", res.data)
      if(res.data.status == 200)
      {
        console.log("data is ", res.data)
        setFutureInterest(res.data.sum)
      }

    })

    
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-total-in-trade`,{}).then((res)=>{
      console.log("New referral data is ", res.data)
      if(res.data.status == 200)
      {
        setFutureTrade(res.data.totalFuture)
        setTotalOpen(res.data.totalOpen)
        setTotalHoldingTank(res.data.totalHoldingTank)
      }

    })
  }


  const[totalTransactions, setTotalTransactions] =  React.useState([]);
  const getAdminTransactions = async () => {
       const userData = {}

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-transactions`,userData).then((res)=>{

      if(res.data.status == 200)
      {
        console.log("transactions ", res.data.total)
        setTotalTransactions(res.data.total)
 
      }

    })
  }

  const getAllCompanyInfo = async(company) => {
    
      var userData = {
        company: company
      }
      
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-company-info`,userData).then((res)=>{
    
        if(res.data.status == 200)
        {
          if(userData)
          {
            setAdminLink(res.data.data.reg_link)
            console.log("the link is set to ", res.data.data.reg_link)
          }
         
         
        }
    
      })
    }

  const taskCompleted = async (id) => {
    var userObj = {
        userid: userid,
        id: id
    }
    console.log("the options we are  sending is ", userObj)
    var getdata = await axios.post(process.env.REACT_APP_BASE + "api/mark-admin-task-Complete", userObj).then(async res2 =>{
        console.log("completed tasks ", res2.data)
    
        if(res2.data.status === 200)
        {
            getAllTasks(userid)
            toast.success('Well Done!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }
    })
}

const getAllTasks = async (newuserid) => {

  var userObj = {
      userid: newuserid
  }

  var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-all-admin-task", userObj).then(async res2 =>{
      console.log("meta tasks ", res2.data)
  
      if(res2.data.status === 200)
      {
          console.log(res2.data.meta)
          setAllTasks(res2.data.meta)
      }
  })
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Admin Dashboard")}
          />

          <Row>
            <Col xl="12" sm="12">
            <WelcomeComp username={username} role={role} adminLink={adminLink} connectedUser={connectedUser} />
              {!isSuperAdmin && role === 'admin' ?
                <MonthlyEarning monthlyDataTotal={monthlyDataTotal} />
                :
                isSuperAdmin && role === 'super' ?
                <div>
                  <SuperAdminInfo />
                </div>
                // <AllEarnings totalInterest={totalInterest} product1={product1} product2={product2} product3={product3} newdate={newdate} excahnge_fee_usdt={excahnge_fee_usdt} excahnge_fee_zar={excahnge_fee_zar} excahnge_fee_btc={excahnge_fee_btc} futureInterest={futureInterest} futureTrade={futureTrade} totalOpen={totalOpen} totalHoldingTank={totalHoldingTank} />
                : ''
              }
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4" >
                <a href="./users" >
                    <Card className="mini-stats-wid">
                    
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Users
                            </p>
                            <h4 className="mb-0">{allUsers}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    </a>
                </Col>

                <Col md="4" >
                <a href="./users" >
                    <Card className="mini-stats-wid">
                      
                    <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Active Users
                            </p>
                            <h4 className="mb-0">{activeUsers}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    
                    </Card>
                    </a>
                </Col>

                {/* <Col md="4">
                    <Card className="mini-stats-wid">
                    <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Average PM
                            </p>
                            <h4 className="mb-0">1234</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col> */}
              </Row>

              {/* 
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
           
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card> 
              */}
            </Col>
          </Row>

          <Row>
          <Col lg={6} sm={12} >
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 text-center">Open Tasks</CardTitle>
                    {typeof allTasks !== 'undefined' && allTasks.length > 0 &&
                      allTasks.map((task, key) => {
                        console.log(task)
                        const parsedTask = JSON.parse(task.meta_value);
                        const { id, title, description, dueDate } = parsedTask;
                        const { status } = task;
                        return (
                          status === 'open' && ( // Use parentheses instead of curly braces
                            <Row key={key} className="border border-2 border-grey mt-2 p-2 bg">
                              <Col md={12}>
                                <Row>
                                  <Col lg="8" sm="12">
                                    <h4><strong>{title}</strong></h4>
                                    <p>{description}</p>
                                  </Col>
                                  <Col lg="4" sm="12">
                                    <h5 className={status === 'open' ? 'text-success' : 'text-danger'}>{status}</h5>
                                    <h6>{dueDate}</h6>
                                  </Col>
                                </Row>
                                {status === 'open' && (
                                  <div>
                                    <button
                                      className="btn btn-success w-50 mx-auto mt-2"
                                      onClick={() => taskCompleted(task)}
                                    >
                                      Mark Done
                                    </button>

                                  </div>
                                )}
                              </Col>
                            </Row>
                          )
                        );
                      })
                    }
                  </CardBody>
                </Card>
              </Col>
          </Row>


          {isSuperAdmin ?
          <Row>
            <Col lg="12">
              <LatestTranaction transactions={totalTransactions} />
            </Col>
          </Row>
          : ''}
        </Container>
      </div>


    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
