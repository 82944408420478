import React, { useEffect, useState } from "react";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";



function AdminPasswordReset() {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [newuserid, setUserid] = useState('')
    const{auth,user} = React.useContext(UserContext)

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setUserid(user.user.userid)
           }
    
          }
        }
      }, [auth])


    function onCurrentPasswordChange(password) {
        setCurrentPassword(password)   
    }

    function onNewPasswordChange(password) {
        setNewPassword(password)   
    }

    function onConfirmPasswordChange(password) {
        setConfirmPassword(password)   
    }

    const onPasswordSave = async() => {
        var userid = newuserid;
        var newPasswordSave = newPassword;
        var currentPasswordSave = currentPassword;
        var confirmPasswordSave = confirmPassword;

        if(confirmPasswordSave !== newPasswordSave) {
            return toast.error("Passwords Don't Match", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

        var updatePasswordObj = {
            userid: userid,
            newPassword: newPasswordSave,
            currentPassword: currentPasswordSave
        }

        console.log("The updated password is  ", updatePasswordObj)

        await axios.post(process.env.REACT_APP_BASE + "api/update-admin-password",updatePasswordObj).then(async res =>{
        
            if(res.data.status == 200)
            {
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
                toast.success("Updated Password Successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
            else
                toast.error("Unable To Update Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
        });
    }


  return (
    <div className='page-content'>

    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    />
<Container fluid>

{/* Render Breadcrumbs */}
<Breadcrumbs title="PasswordReset" breadcrumbItem="PasswordReset" />
    <Row>
    <div>
    <h1 className="mb-6 mt-6 text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Password</h1>

    <div className="mb-6">
         <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
             Current Password 
         </label>

         <input type="password" value={currentPassword} onChange={(e) => onCurrentPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                
     </div>

     <div className="mb-6">
         <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
             New Password 
         </label>

         <input type="password" value={newPassword} onChange={(e) => onNewPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                
     </div>
     
     
     <div className="mb-6">
         <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
             Confirm Password 
         </label>

         <input type="password" value={confirmPassword} onChange={(e) => onConfirmPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                
     </div>

     <div className="flex items-center justify-between mb-2">
         <button  onClick={(e) => onPasswordSave(e.target.value)} className="btn btn-success  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
             Save
         </button>
    </div>

    </div>
    </Row>
        </Container>
    </div>
  )
}

export default AdminPasswordReset