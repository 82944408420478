import React, { useEffect, useRef } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getActiveElement } from "formik";

const AssignNew = props => {

    const{auth,user} = React.useContext(UserContext)
    const[role, setUserRole] =  React.useState('viewer');
    const[name, setName] =  React.useState('');
    const[lastname, setLastName] = React.useState('');
    const[email, setEmail] =  React.useState('');
    const[password, setPassword] =  React.useState('');
    const[referral, setReferral] =  React.useState('');
    const[referralParent, setReferralParent] =  React.useState('');
    const[referralArray, setReferralArray] =  React.useState([]);
    const[onceOffFee, setOnceOffFee] =  React.useState(0);
    const[maxOnceOffFee, setMaxOnceOffFee] =  React.useState(0); 
    const[cell, setCell] = React.useState('');
    const[userRole, setAdminRole] = React.useState('');


    
    const[possibleUser, setPossibleUser] = React.useState(null);
    const[findUserid, setFindUserid] = React.useState('');
    const[onceOffPercentage, setOnceOffPercentage] = React.useState(0);
    
    
    // |Admin

    const[userid, setUserid] = React.useState('');
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
            // 
            
            findAllAdmin()
            
        }
      }
    }, [auth])

    // const getAllReferralArray = async() => {
    //   var agentArr = []
    //   var requiredObj = {
    //     role: 'admin'
    //   }
    //   await axios.post(`${process.env.REACT_APP_API_BASE_URL}find-all-admins`,requiredObj).then((res)=>{
    //     if(res.data.status === 200)
    //     {
    //       console.log(res.data.response)
    //       setReferralArray(res.data.response)
    //       agentArr = res.data.response
    //     }else{
    //       toast.error('Unable to find any data', {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //         });
    //     }
    //   })

    //   return agentArr
    // }

    const agentReferralParent = async (parent) => {
      
      var refferralArr = await getAllReferralArray()
      console.log("agent parent " + parent, refferralArr)
      setReferralParent(parent)
      var targetObject = refferralArr.find(obj => obj.userid === parent);
      console.log(targetObject)
      if(targetObject)
      {
        setMaxOnceOffFee(parseFloat(targetObject.onceOffPayment))
      }
      
    }


    const findUseridChange = async (findUserid) => {
        setFindUserid(findUserid);
        console.log("finding userid change: ", findUserid)
        await getPossibleUserid(findUserid);
    }

    const getPossibleUserid = async(newUser) => {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-possible-userid`,{userid: newUser}).then((res)=>{

            if(res.data.status == 200)
            {
                console.log("data is: ", res.data)
                if(res.data.userid)
                {
                    setPossibleUser(res.data.userid.userid)
                }else{
                    setPossibleUser(null)
                }
                
            }

        });
    }

    const findAllAdmin = async() => {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-all-admin`).then((res)=>{
            console.log("receiving: ", res.data)
            if(res.data.status == 200)
            {
               
                console.log("data is: ", res.data.data)
                setReferralArray(res.data.data)
        
            }

        });
    }

    const lastNameChange = async (lastName) => {
      setLastName(lastName);
    }

    const onSubmitAdmin = async () => {

        var formObj = {
            userid: possibleUser, 
            adminuserid: referralParent, 
            onceOffPercentage: onceOffPercentage
        }

        console.log("data is: ", formObj)


        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-new-agent`,formObj).then((res)=>{

            if(res.data.status == 200)
            {
                return toast.success('SuccessFully Registered', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                return toast.error( res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }

        })
    }

    const changeReferralParent = async (parent) => {
      console.log("changed to parent " + parent)
      setReferralParent(parent)
      var targetObject = referralArray.find(obj => obj.userid === parent);
      console.log(targetObject)
      if(targetObject)
      {
        setMaxOnceOffFee(parseFloat(targetObject.onceOffPayment))
      }
      
    }


  //meta title
  document.title = "New Admin | AlphaX Green";

  return (
    <React.Fragment>
            <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="New User" />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Assign User To Admin</CardTitle>

                  <Form>
                    <div className="mb-3">
     
                      <Label htmlFor="formrow-firstname-Input">Userid To Assign To Admin</Label>
                      {possibleUser?
                        <p className="text-danger">{possibleUser}</p>
                        : <p className="text-muted">Finding Userid...</p>}
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Userid"
                        value={findUserid}
                        onChange={(e) => {findUseridChange(e.target.value)}}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Parent Agent</Label>
                      <select
                            id="formrow-InputState"
                            className="form-control"
                            value={referralParent}
                            onChange={(e) => {changeReferralParent(e.target.value)}}
                          >
                            <option value="">Select</option>
                            {
                            //   console.log("check: ", referralArray)
                            // referralArray.map((adminReferral, key) => {
                            //   <option key={key} value={adminReferral.userid}>{adminReferral.name}</option>
                            // })
                            referralArray.map((adminReferral, index) => (
                              <option key={index} value={adminReferral.userid}>{adminReferral.name}</option>
                            ))
                            }
                            
                          </select>
                    </div>

                    <div className="mb-3">
     
                        <Label htmlFor="formrow-firstname-Input">Once Off Percentage</Label>
                        <Input
                        type="number"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Once Off Percentage"
                        value={onceOffPercentage}
                        onChange={(e) => {setOnceOffPercentage(e.target.value)}}
                        />
                    </div>

                    <div>
                      <a onClick={onSubmitAdmin} className="btn btn-primary w-md">
                        Submit
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default AssignNew;
