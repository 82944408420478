import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

  const UserSingleTable = ({tableData, columns, title}) => {
  const dispatch = useDispatch();

  const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }));

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "#",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: cellProps => {
  //         return <input type="checkbox" />;
  //       },
  //     },
  //     {
  //       Header: "Transaction ID",
  //       accessor: "trans_id",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: cellProps => {
  //         return <OrderId {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Userid",
  //       accessor: "userid",
  //       disableFilters: true,
  //       filterable: true,
  //       Cell: cellProps => {
  //         return <BillingName {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Date",
  //       accessor: "createdAt",
  //       disableFilters: true,
  //       filterable: true,
  //       Cell: cellProps => {
  //         return <Date {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Amount",
  //       accessor: "amount",
  //       disableFilters: true,
  //       filterable: true,
  //       Cell: cellProps => {
  //         return <Total {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //       disableFilters: true,
  //       filterable: true,
  //       Cell: cellProps => {
  //         return             <p

  //         >
  //           {cellProps.value == '0' ? 'Pending' : cellProps.value == '1' ? 'Approved' : 'Declined'}
  //         </p>
  //       },
  //     },
  //     {
  //       Header: "Method",
  //       accessor: "tran_type",
  //       disableFilters: true,
  //       Cell: cellProps => {
  //         return <PaymentMethod {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Currency",
  //       accessor: "currency",
  //       disableFilters: true,
  //       Cell: cellProps => {
  //         // return <PaymentMethod {...cellProps} />;
  //                   return (
  //           <p

  //           >
  //             {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
  //           </p>
  //         );
  //       },
  //       // Header: "View Details",
  //       // disableFilters: true,
  //       // accessor: "view",
  //       // Cell: cellProps => {
  //       //   return (
  //       //     <Button
  //       //       type="button"
  //       //       color="primary"
  //       //       className="btn-sm btn-rounded"
  //       //       onClick={toggleViewModal}
  //       //     >
  //       //       View Details
  //       //     </Button>
  //       //   );
  //       // },
  //     },
  //   ],
  //   []
  // );

  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders();
    }
  }, [onGetOrders, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">{title}</div>
          <TableContainer
            columns={columns}
            data={tableData}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UserSingleTable
