import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
import { CSVLink, CSVDownload } from "react-csv";
import UserTransactionsReport from "./TransactionsReport";
function transactions() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('User Transactions');

    const[data, setData] =  React.useState([]);
    const[header, setHeader] =  React.useState([]);
    const[displayUserSpinner, setDisplayUserSpinner] =  React.useState(true);
    const[userDetails, setUserDetails] =  React.useState(null);

    const columns = useMemo(
        () => [
          {
            Header: "Transaction ID",
            accessor: "trans_id",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Userid",
            accessor: "userid",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Date {...cellProps} />;
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />;
            },
          },
          {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return             <p
    
              >
                {cellProps.value == '0' ? 'Pending' : cellProps.value == '1' ? 'Approved' : 'Declined'}
              </p>
            },
          },
          {
            Header: "Method",
            accessor: "tran_type",
            disableFilters: true,
            Cell: cellProps => {
              return <PaymentMethod {...cellProps} />;
            },
          },
          {
            Header: "Currency",
            accessor: "currency",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
            // Header: "View Details",
            // disableFilters: true,
            // accessor: "view",
            // Cell: cellProps => {
            //   return (
            //     <Button
            //       type="button"
            //       color="primary"
            //       className="btn-sm btn-rounded"
            //       onClick={toggleViewModal}
            //     >
            //       View Details
            //     </Button>
            //   );
            // },
          },
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits(userid)
            setDisplayUserSpinner(false);
            getUserDetails(userid)
          }
        }
      }, [auth])
    
    
      const getUserDeposits = async (userid) => {
    
        const userData = {
          userid: userid,
        }
    
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-user-transactions`,userData).then((res)=>{

          var newheaders = [
            { label: "Userid", key: "userid" },
            { label: "Amount", key: "amount" },
            { label: "Cell", key: "cell" },
            { label: "Type", key: "tran_type" },
            { label: "Status", key: "status" },
            { label: "Currency", key: "currency" },
            { label: "Referral", key: "referral" },
            { label: "Transactions ID", key: "trans_id" },
            { label: "Date", key: "createdAt" },
          ];


          setHeader(newheaders)
    
          if(res.data.status == 200)
          {
            setTableData(res.data.data)
            setData(res.data.data)
            
          }
    
        })
      }

      const getUserDetails = async(userid) => {
        
        const userData = {
          userid: userid,
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userData).then((res)=>{
         
          if(res.data.status === 200)
          {
            setUserDetails(res.data.user)
          }
        });
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Transactions" breadcrumbItem="Transactions" />

<Row>
  <Col md="12" sm="12">
  {!displayUserSpinner ?
                <div>
  <CSVLink
  filename={"transactions.csv"}
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={data}
                    headers={header}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    Download CSV
                    </CSVLink>
        {userDetails ?
          <div className="mt-3">
            <UserTransactionsReport user={userDetails} />
          </div>
        : ''}
        <UserSingleTable tableData={tableData} columns={columns} title={title} />

        </div>
        :
        <Spinner type="grow" className="ms-2" color="success" />
        }
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default transactions

