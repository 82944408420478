import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { change } from "redux-form"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewTasks = () => {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[inUpdatingMode, setInUpdatingMode] =  React.useState(false);
    const[showClosed, setShowClosed] =  React.useState(false);
    
    

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            console.log("user data is ", user.user)
            getUsersData(user.user)
            getAllTasks(user.user.userid)
            setUserid(user.user.userid)
           }
    
          }
        }
      }, [auth])

  //meta title
  document.title="Create Task";

  const inpRow = [{ name: "", file: "" }]
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [inputFields, setinputFields] = useState(inpRow)

  const startDateChange = date => {
    console.log("date is: ", date)
    setstartDate(date)
  }

  const [taskName, setTaskName] = useState('')
  const [taskDescription, setTaskDescription] = useState('')
  const [assigendAdmin, setAssigendAdmin] = useState('')
  const [adminUsers, setAdminUsers] = useState([])
  const [allTasks, setAllTasks] = useState([])
  const [editingId, setEditingId] = useState(null)
  


  const onTaskNameChange = async (taskName) => {
    setTaskName(taskName)
  }

  
  const onTaskDescription = async (description) => {
    setTaskDescription(description)
  }

  
  const changeTeamMember = async (teamMember) => {
    setAssigendAdmin(teamMember)
  }

  const getUsersData = async(info) => {
    var dataObj = {
      info: info
    }

    console.log("the data obj is", dataObj)
    var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-all-admin-users", dataObj).then(async res2 =>{
        
        if(res2.data.status === 200)
        {
            console.log(res2.data.names)
            setAdminUsers(res2.data.names)
        }
    })

    }


    // ------- DEPRECATED -------
    // const onCreateTask = async () => {
    //     var taskObj = {
    //         from: userid,
    //         name: taskName,
    //         description: taskDescription,
    //         assigendAdmin: assigendAdmin,
    //         date: startDate
    //     }
    //     var getdata = await axios.post(process.env.REACT_APP_BASE + "api/create-new-admin-task", taskObj).then(async res2 =>{
        
    //         if(res2.data.status === 200)
    //         {
    //             getAllTasks(userid)
    //             setTaskName('')
    //             setTaskDescription('')
    //             return toast.success('Task Created Successfully', {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "dark",
    //                 });
    //         }
    //     })

        
    // }

    const onCreateTask = async () => {

      if(typeof adminUserArr === "undefined" || adminUserArr.length < 0) 
      {
        return toast.error('Need to assign a task to atleast 1 person', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }

      var taskObj = {
          from: userid,
          name: taskName,
          description: taskDescription,
          assigendAdmin: adminUserArr,
          date: startDate
      }
      var getdata = await axios.post(process.env.REACT_APP_BASE + "api/create-new-admin-task", taskObj).then(async res2 =>{
      
          if(res2.data.status === 200)
          {
              getAllTasks(userid)
              setTaskName('')
              setTaskDescription('')
              setAdminUserArr()

              toast.success('Task Created Successfully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });

                  // return setTimeout(() => {window.location.href="/view-tasks" }, 2000);
          }else{
            return toast.error('Unable To Create Task', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
      })

      
  }

    const getAllTasks = async (newuserid) => {

        var userObj = {
            userid: newuserid
        }

        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-all-admin-task", userObj).then(async res2 =>{
            console.log("meta tasks ", res2.data)
        
            if(res2.data.status === 200)
            {
                console.log(res2.data.meta)
                setAllTasks(res2.data.meta)
            }
        })
    }

    const submitUpdateTask = async (taskid) => {

      var taskObj = {
          taskid: taskid
      }

      var getdata = await axios.post(process.env.REACT_APP_BASE + "api/update-admin-task", taskObj).then(async res2 =>{
          console.log("meta tasks ", res2.data)
      
          // if(res2.data.status === 200)
          // {
          //     console.log(res2.data.meta)
          //     setAllTasks(res2.data.meta)
          // }
      })
  }

  const updateTask = async (task) => {
    setInUpdatingMode(true)
    const parsedTask = JSON.parse(task.meta_value);
    console.log("Task: ", task)
    setTaskName(parsedTask.title)
    setTaskDescription(parsedTask.description)
    // Parse the ISO string from the task object to a Date object
const dueDate = new Date(parsedTask.dueDate);

// Call startDateChange with the Date object
startDateChange(dueDate); 

setEditingId(task.id);
  }

    const taskCompleted = async (id) => {
        var userObj = {
            userid: userid,
            id: id
        }
        console.log("the options we are  sending is ", userObj)
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/mark-admin-task-Complete", userObj).then(async res2 =>{
            console.log("completed tasks ", res2.data)
        
            if(res2.data.status === 200)
            {
                getAllTasks(userid)
                toast.success('Well Done!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
        })
    }


    const [adminUserArr, setAdminUserArr] = useState([])
    const addAdminUser = async () => {
      console.log(assigendAdmin.toString())
      setAdminUserArr([...adminUserArr, assigendAdmin.toString()])
   
        // adminUserArr.push(assigendAdmin.toString())

      console.log("admin user is ", assigendAdmin, adminUserArr)
    }

    const removeUser = async(user) => {
      console.log("user is ", user)
      var array = [...adminUserArr]; // make a separate copy of the array
      var index = array.indexOf(user.toString())
      if (index !== -1) {
        array.splice(index, 1);
        setAdminUserArr(array);
    }
  }

  const updateCurrentTask = async() => {

    var taskObj = {
        taskid: editingId,
        name: taskName,
        description: taskDescription,
        date: startDate
    }
    var getdata = await axios.post(process.env.REACT_APP_BASE + "api/update-new-admin-task", taskObj).then(async res2 =>{
    
        if(res2.data.status === 200)
        {
            getAllTasks(userid)
            setTaskName('')
            setTaskDescription('')
            setAdminUserArr()

            toast.success('Task Created Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                // return setTimeout(() => {window.location.href="/view-tasks" }, 2000);
        }else{
          return toast.error('Unable To Create Task', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
    })
  }

  const turnObjStrToJSON = async(obj) => {
    var metaValue = JSON.parse(obj.meta_value);
    console.log(metaValue);
    return metaValue;
  }


  return (
    <>
      <div className="page-content">
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Create Tasks" />

          <Row>
            <Col lg="12" sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Task</CardTitle>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Task Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              placeholder="Enter Task Name..."
                              value={taskName}
                              onChange={(e) => onTaskNameChange(e.target.value)} 
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Task Description
                          </Label>
                          <Col lg="10">
                            {/* <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              value={taskDescription}
                              onChange={(e) => onTaskDescription(e.target.value)} 
                            /> */}

                            <textarea className="form-control"
                              value={taskDescription}
                              onChange={(e) => onTaskDescription(e.target.value)} 
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Task Due Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col md={6} className="pr-0">
                                <DatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={startDateChange}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>

                        {!inUpdatingMode ?
                        <div className="inner-repeater mb-4">
                          <div className="d-inline-block">
                        {typeof adminUserArr !== 'undefined' && adminUserArr.length > 0 ?
                              adminUserArr.map((user, key) => (
                                <div style={{borderRadius: "15px", padding: "7px", marginBottom: "5px", marginLeft: "5px", width: "fit-content"}} key={key} className="d-inline-block bg-success text-white">{user} 
                                <a className="ml-3 text-danger " onClick={(e) => {removeUser(user)}}> <strong>X</strong> </a> </div>
                              ))
                          : ''}
                          </div>
                          <div className="inner form-group mb-0 row">
      
                            <Label className="col-form-label col-lg-2">
                              Assign Team Member
                            </Label>
                            <div
                              className="inner col-lg-10 ml-md-auto"
                              id="repeater"
                            >
                              <select className="form-control" value={assigendAdmin}  onChange={(e) => {changeTeamMember(e.target.value)}} >
                                <option value='' >Please Select User</option>
                                {typeof adminUsers !== undefined && adminUsers.length > 0 ?
                                
                                    Object.keys(adminUsers).map((id, key) => (
                                    // <div key={key}>
                                    //     <div>{console.log(user)}</div>
                                    <option key={key} value={adminUsers[id].name} >{adminUsers[id].name}</option>
                                    // </div>
                                ))
                                : ''}
                              </select>
                            </div>

                            {assigendAdmin !== '' ? <button type="button" style={{width:"fit-content"}} className="btn btn-success" onClick={addAdminUser} >Add</button> : ''}
                          </div>
                     
                        </div>
                        : ''}
                       
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                    <Col lg="10">
        

                      {inUpdatingMode ?
                      <div>
                        <Button type="button" className="btn btn-primary m-1" onClick={updateCurrentTask} color={!inUpdatingMode ? 'primary' : 'warning'}>
                          {!inUpdatingMode ? 'Create Task' : 'Update Task'}
                        </Button>

                        <Button type="button" className="btn btn-primary" onClick={() => setInUpdatingMode(false)} color="success">
                          Create a New Task
                        </Button>
                      </div>

                    : 
                    <Button type="button" className="btn btn-primary m-1" onClick={onCreateTask} color={!inUpdatingMode ? 'primary' : 'warning'}>
                    Create Task
                  </Button>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>

         
          {! showClosed ?
          <div>
             <button className="btn btn-danger text-white  w-50" onClick={() => {setShowClosed(!showClosed)}}>{!showClosed ? "Show Closed Tasks" : "Show Open Tasks"}</button>
            <Row>
              <Col lg={6} sm={12} >
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 text-center">Open Tasks</CardTitle>
                    {typeof allTasks !== 'undefined' && allTasks.length > 0 &&
                      allTasks.map((task, key) => {
                        const parsedTask = JSON.parse(task.meta_value);
                        const { id, title, description, dueDate } = parsedTask;
                        const { status } = task;
                        return (
                          status === 'open' && ( // Use parentheses instead of curly braces
                            <Row key={key} className="border border-2 border-grey mt-2 p-2 bg">
                              <Col md={12}>
                                <Row>
                                  <Col lg="8" sm="12">
                                    <h4><strong>{title}</strong></h4>
                                    <p>{description}</p>
                                  </Col>
                                  <Col lg="4" sm="12">
                                    <h5 className={status === 'open' ? 'text-success' : 'text-danger'}>{status}</h5>
                                    <h6>{dueDate}</h6>
                                  </Col>
                                </Row>
                                {status === 'open' && (
                                  <div>
                                    <button
                                      className="btn btn-success w-50 mx-auto mt-2"
                                      onClick={() => taskCompleted(task)}
                                    >
                                      Mark Done
                                    </button>
                                    <button
                                      className="btn btn-warning w-50 mx-auto mt-2"
                                      onClick={() => updateTask(task)}
                                    >
                                      Edit Task
                                    </button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          )
                        );
                      })
                    }
                  </CardBody>
                </Card>
              </Col>

              <Col lg={6} sm={12} >
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 text-center">Due Tasks</CardTitle>
                    {typeof allTasks !== 'undefined' && allTasks.length > 0 &&
                      allTasks.map((task, key) => {
                        const parsedTask = JSON.parse(task.meta_value);
                        const { id, title, description, dueDate } = parsedTask;
                        const { status } = task;
                        return (
                          status === 'due' && ( // Use parentheses instead of curly braces
                            <Row key={key} className="border border-2 border-grey mt-2 p-2 bg">
                              <Col md={12}>
                                <Row>
                                  <Col lg="8" sm="12">
                                    <h4><strong>{title}</strong></h4>
                                    <p>{description}</p>
                                  </Col>
                                  <Col lg="4" sm="12">
                                    <h5 className={status === 'due' ? 'text-warning' : 'text-danger'}>{status}</h5>
                                    <h6>{dueDate}</h6>
                                  </Col>
                                </Row>
                
                                  <div>
                                    <button
                                      className="btn btn-success w-50 mx-auto mt-2"
                                      onClick={() => taskCompleted(id)}
                                    >
                                      Mark Done
                                    </button>
                                    <button
                                      className="btn btn-warning w-50 mx-auto mt-2"
                                      onClick={() => updateTask(id)}
                                    >
                                      Edit Task
                                    </button>
                                  </div>
                                
                              </Col>
                            </Row>
                          )
                        );
                      })
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          :
          <div>
            <button className="btn btn-success text-white  w-50" onClick={() => {setShowClosed(!showClosed)}}>{!showClosed ? "Show Closed Tasks" : "Show Open Tasks"}</button>
          
                      <Row>
                      <Col lg={6} sm={12} >
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4 text-center">Closed Tasks</CardTitle>
                            {typeof allTasks !== 'undefined' && allTasks.length > 0 &&
                              allTasks.map((task, key) => {
                                const parsedTask = JSON.parse(task.meta_value);
                                const { id, title, description, dueDate } = parsedTask;
                                const { status } = task;
                                return (
                                  status === 'closed' && ( // Use parentheses instead of curly braces
                                    <Row key={key} className="border border-2 border-grey mt-2 p-2 bg">
                                      <Col md={12}>
                                        <Row>
                                          <Col lg="8" sm="12">
                                            <h4><strong>{title}</strong></h4>
                                            <p>{description}</p>
                                          </Col>
                                          <Col lg="4" sm="12">
                                            <h5 className={status === 'closed' ? 'text-danger' : 'text-danger'}>{status}</h5>
                                            <h6>{dueDate}</h6>
                                          </Col>
                                        </Row>
                                        {status === 'closed' && (
                                          <div>
                                            <button
                                              className="btn btn-success w-50 mx-auto mt-2"
                                              onClick={() => taskCompleted(id)}
                                            >
                                              Open Again
                                            </button>
                                            <button
                                              className="btn btn-warning w-50 mx-auto mt-2"
                                              onClick={() => updateTask(id)}
                                            >
                                              Edit Task
                                            </button>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  )
                                );
                              })
                            }
                          </CardBody>
                        </Card>
                      </Col>
          
                    
                    </Row>
                    </div>
          }
        </Container>
      </div>
    </>
  )
}

export default ViewTasks