import React from 'react';

const Pdate = (cell) => {
    return cell.value ? cell.value : '';
};

const Type = (cell) => {
    return cell.value ? cell.value : '';
};

const Value = (cell) => {
    return cell.value ? cell.value : '';
};

const ValueinUSD = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return cell.value ? cell.value : '';
};

const Coin = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    Pdate,
    Type,
    Value,
    ValueinUSD,
    Status,
    Coin
};