import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  
} from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"
import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Dropzone from "react-dropzone"

function Segregation() {
    
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Segregation Page');

    const[popUpload, setPopUpload] =  React.useState([]);
    const[pofUpload, setPofUpload] =  React.useState([]);

    const[popErrorMessage, setPOPErrorMessage] =  React.useState(null);
    const[pofErrorMessage, setPOFErrorMessage] =  React.useState(null);
    
    const[currentTransactionID, setCurrentTransactionID] =  React.useState(null);

    const[transactions, setTransactions] =  React.useState([]);
    const[show, setShow] =  React.useState(false);
    const[showPOF, setShowPOF] =  React.useState(false);

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "User",
            accessor: "userinfo",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return (
                <p>
                  {cellProps.row.original.name + " " + cellProps.row.original.lastname}
                </p>
              );
            },
          },
          {
            Header: "Wealth USDT",
            accessor: "usdtTotal",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {parseFloat(cellProps.value).toFixed(2)}
                </p>
              );
            },
          },
          {
            Header: "Wealth ZAR",
            accessor: "zarTotal",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {parseFloat(cellProps.value).toFixed(2)}
                </p>
              );
            },
          },
          {
            Header: "Wealth BTC",
            accessor: "btcTotal",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value}
                </p>
              );
            },
          }, 
          {
            Header: "Capital USDT",
            accessor: "totalUSDTFromCapital",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {parseFloat(cellProps.value).toFixed(2)}
                </p>
              );
            },
          },
          {
            Header: "Capital ZAR",
            accessor: "totalZARFromCapital",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {parseFloat(cellProps.value).toFixed(2)}
                </p>
              );
            },
          },
          {
            Header: "Capital BTC",
            accessor: "totalBTCFromCapital",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value}
                </p>
              );
            },
          },
          {
            Header: "POP",
            accessor: "firstDepositTransactionPOP",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              const [imageUrl, setImageUrl] = useState('');
          
              useEffect(() => {
                async function fetchImageUrl() {
                  const poa = await getUploadImage(cellProps.value);
          
                  if (poa) {
                    setImageUrl(poa);
                  }
                }
                fetchImageUrl();
              }, [cellProps.value]);
          
              return (
                !cellProps.value ? (
                  <button className="btn btn-rounded btn-success" onClick={() => uploadProofOfPayment(cellProps.row.original.firstDepositTransactionID)}>
                    Upload
                  </button>
                ) : (
                  <div>
                    {cellProps.value && !cellProps.value.includes('.png') && !cellProps.value.includes('.jpeg') && !cellProps.value.includes('.jpg')
                      ? <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/' + cellProps.value} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                      : <Zoom><img src={imageUrl} className="w-50 img-thumbnail" /></Zoom>}
                  </div>
                )
              );
            },
          },
        {
            Header: "Proof of Funds",
            accessor: "firstDepositTransactionPOF",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              const [imageUrl, setImageUrl] = useState('');
          
              useEffect(() => {
                async function fetchImageUrl() {
                  const poa = await getUploadImage(cellProps.value);
          
                  if (poa) {
                    setImageUrl(poa);
                  }
                }
                fetchImageUrl();
              }, [cellProps.value]);
          
              return (
                !cellProps.value ? (
                  <button className="btn btn-rounded btn-success" onClick={() => uploadProofOfFunds(cellProps.row.original.firstDepositTransactionID)}>
                    Upload
                  </button>
                ) : (
                  <div>
                    {cellProps.value && !cellProps.value.includes('.png') && !cellProps.value.includes('.jpeg') && !cellProps.value.includes('.jpg')
                      ? <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/' + cellProps.value} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                      : <Zoom><img src={imageUrl} className="w-50 img-thumbnail" /></Zoom>}
                  </div>
                )
              );
            },
          },      
    
        ],
        []
      );

      const getUploadImage = async(image) => {
        console.log("image: " + image)
        var poa = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: image})
        .then(res2 => {
           
          return res2.data;
        })
        console.log(poa)
        return poa;
      }

      const uploadProofOfPayment = async(transactionID) => {
        console.log(transactionID)
        setShow(true)
        setCurrentTransactionID(transactionID)
      }

      const uploadProofOfFunds = async(transactionID) => {
        setShowPOF(true)
        setCurrentTransactionID(transactionID)
      }

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits()
    
          }
        }
      }, [auth])
    
      const getUserDeposits = async () => {
        
        await axios.get(process.env.REACT_APP_BASE + "api/user-segregation").then(async (res)=>{
            console.log(res.data)
            if(res.data.status === 200)
                setTransactions(res.data.data)
            else
                setTransactions([])
          
        });
      }

      const onCloseClick = async() => {
        setShow(false)
        setPOPErrorMessage(null)
        setCurrentTransactionID(null)
      }

      function handlePOPFiles(files) {
    
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
     
        setPopUpload(files)
        
      }

      
      function handlePOFFiles(files) {
    
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
     
        setPofUpload(files)
        
      }

      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

      const onClosePOFClick = async() => {
        setShowPOF(false)
        setPOFErrorMessage(null)
        setCurrentTransactionID(null)
      }

      const uploadPOP = async() => {
        var formD = new FormData();
        if(!popUpload || popUpload.length === 0)
        {
            return setPOPErrorMessage("No Proof of Payment Upload found!")
        }

        if(!currentTransactionID)
        {
            return setPOPErrorMessage("No Transaction ID found!")
        }

        formD.append('trans_id', currentTransactionID)
        formD.append('upload_type','pop')
        formD.append("uploadedfile",popUpload[0])

        await axios.post(process.env.REACT_APP_BASE + "api/update-upload-segregation",formD).then(async res =>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setTimeout(() => {window.location.reload(); }, 200);
            }

        })
      }

      const uploadPOF = async() => {
        if(!pofUpload || pofUpload.length === 0)
        {
            return setPOPErrorMessage("No Proof of Funds Upload found!")
        }

        if(!currentTransactionID)
        {
            return setPOPErrorMessage("No Transaction ID found!")
        }

        var formD = new FormData();

        formD.append('trans_id', currentTransactionID)
        formD.append('upload_type','pof')
        formD.append("uploadedfile",pofUpload[0])

        await axios.post(process.env.REACT_APP_BASE + "api/update-upload-segregation",formD).then(async res =>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                setTimeout(() => {window.location.reload(); }, 200);
            }

        })

      }

  return (
    <React.Fragment>

    <Modal isOpen={showPOF} toggle={onClosePOFClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
            <h3 className="text-center">Proof Of Funds</h3>
          <Col lg={12}>
            {pofErrorMessage ?
                <p className="text-danger">pofErrorMessage</p>
                : ''
            }
            <div className="text-center">
            <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                        <div className="dropzone-previews mt-3" id="file-previews">
                                                            {pofUpload.map((f, i) => {
                                                                return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            style={{maxHeight: "150px"}}
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                        </Col>
                                                                        <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                        </Col>
                                                                    </Row>
                                                                    </div>
                                                                </Card>
                                                                )
                                                            })}
                                                            </div>
                                                            <Dropzone
                                                            onDrop={acceptedFiles => {
                                                                handlePOFFiles(acceptedFiles)
                                                            }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                <div className="dropzone">
                                                                    <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                    >
                                                                    <input {...getInputProps()} type="file" name="userUpload" />
                                                                    <div className="dz-message needsclick">
                                                                        <div className="mb-3">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                        </div>
                                                                        <h4>Drop Proof of Funds here or click to upload.</h4>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Dropzone>
                                                                        </Col>
                                                                    </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={() => uploadPOF()}
              >
                Yes, Upload!
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={() => onClosePOFClick()}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>

    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
        <h3 className="text-center">Proof Of Payment</h3>
            {popErrorMessage ?
                <p className="text-danger">popErrorMessage</p>
                : ''
            }
          <Col lg={12}>
            <div className="text-center">
            <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                        <div className="dropzone-previews mt-3" id="file-previews">
                                                            {popUpload.map((f, i) => {
                                                                return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            style={{maxHeight: "150px"}}
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                        </Col>
                                                                        <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                        </Col>
                                                                    </Row>
                                                                    </div>
                                                                </Card>
                                                                )
                                                            })}
                                                            </div>
                                                            <Dropzone
                                                            onDrop={acceptedFiles => {
                                                                handlePOPFiles(acceptedFiles)
                                                            }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                <div className="dropzone">
                                                                    <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                    >
                                                                    <input {...getInputProps()} type="file" name="userUpload" />
                                                                    <div className="dz-message needsclick">
                                                                        <div className="mb-3">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                        </div>
                                                                        <h4>Drop Proof of Payment here or click to upload.</h4>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Dropzone>
                                                                        </Col>
                                                                    </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={() => uploadPOP()}
              >
                Yes, Upload!
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={() => onCloseClick()}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>

    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
  <Col md="12" sm="12">
        <UserSingleTable tableData={transactions} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Segregation

