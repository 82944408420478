import React from 'react'

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";

  import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function DisplayUploads({value, onClickFunction, upload, uploadImage}) {
  return (
    <div>
        <button value={value} onClick={(e) => {onClickFunction(value)}} style={{padding: "unset", border: "unset"}}>
            <i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}>
            </i>
        </button>

        {(upload && !upload.includes('.png') && !upload.includes('.jpeg') && !upload.includes('.jpg') )?
         <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+upload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
        :
        <Zoom><img src={uploadImage} className="w-25 img-thumbnail"/></Zoom> }
    </div>
  )
}

export default DisplayUploads