import React, { useEffect, useState, useMemo } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";

import PerformanceGraph from "pages/Reports/Graphs/PerformanceGraph";
import PerformanceTable from "pages/Tables/PerformanceTable";


function PerformanceGraphContainer({back}) {

  return (
    <div>
      <Button className="btn btn-danger mb-3" onClick={() => back()}>Back</Button>
      <p>Performance Information</p>
      <PerformanceGraph />
      <PerformanceTable />
    </div>
  )
}
PerformanceGraphContainer.propTypes = {
  }
export default PerformanceGraphContainer