import React, { useEffect, useState } from "react";

// import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import PropTypes from 'prop-types'

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"
function GraphSelection({optionClicked, role = 'viewer', option='', MainTitle, Cardtitle, Cardtext}) {
    
  return (

                    <Col lg={4} md={4}>
                        <a value="transactions" onClick={(e) => {optionClicked(option)}}>
                            <Card outline color="primary" className="border c1-expand-hover h-100">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-primary">
                                    <i className="mdi mdi-bullseye-arrow me-3" />{MainTitle}
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-primary">{Cardtitle}</CardTitle>
                                <CardText className="text-primary">
                                    {Cardtext}
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

  )
}
GraphSelection.propTypes = {
    optionClicked: PropTypes.func,
    role: PropTypes.any
  }
  
export default GraphSelection