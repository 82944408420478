import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

  const FilterTable = ({users}) => {
  const dispatch = useDispatch();

  const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }));

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

 
  const columns = useMemo(
    () => [
      {
        Header: "Userid",
        accessor: "userid",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Username",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Inv Amount",
        accessor: "inv_amount",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Comms Perc",
        accessor: "new_percentage",
        disableFilters: true,
        Cell: cellProps => {
            return <Total {...cellProps} />;
        },
      },
      {
        Header: "Comms Amount",
        accessor: "comms_amount",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Referral Perc",
        accessor: "referral_percentage",
        disableFilters: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Referral Amount",
        accessor: "referral_amount",
        disableFilters: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
        // Header: "View Details",
        // disableFilters: true,
        // accessor: "view",
        // Cell: cellProps => {
        //   return (
        //     <Button
        //       type="button"
        //       color="primary"
        //       className="btn-sm btn-rounded"
        //       onClick={toggleViewModal}
        //     >
        //       View Details
        //     </Button>
        //   );
        // },
      
    ],
    []
  );


  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders();
    }
  }, [onGetOrders, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const editUserBtn = async(userid) => {
    console.log("Clicked Button " + userid)
    window.location.href="/edit-user/" + userid
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">All Users</div>
          <TableContainer
            columns={columns}
            data={users}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={30}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FilterTable




