import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner,
    InputGroup,
    Label
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"

import ReportSelection from "./container/reportSelection";
import TransactionsReportContainer from "./container/reportContainers/TransactionsReportContainer";
import SalesReportContainer from "./container/reportContainers/SalesReportContainer";
import ProceedsReportContainer from "./container/reportContainers/ProceedsReportContainer";
import AgentCommsContainer from "./container/reportContainers/AgentCommsContainer";
import ExchangesReportContainer from "./container/reportContainers/ExchangesReportContainer";
import DepositCommsContainer from "./container/reportContainers/DepositCommsContainer";
import WithdrawReportContainer from "./container/reportContainers/WithdrawReportContainer";
import ProductExpireReportContainer from "./container/reportContainers/ProductExpireReportContainer";

function ReportList() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[email, setEmail] =  React.useState('');
    const[showLoadingSpinner, setShowLoadingSpinner] =  React.useState(false);
    const[selectedOption, setSelectedOption] =  React.useState("");

    const[role, setRole] =  React.useState('');
    const[companyID, setCompanyID] =  React.useState('');
    const[companies, setCompanies] =  React.useState([]);
    
    
    // Set all displays
    const[displaySelection, SetDisplaySelection] =  React.useState(true);


    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setEmail(user.user.email)
            setUserid(user.user.userid);
            setRole(user.user.u_role)
            console.log("Role is " + user.user.u_role)
            if(user.user.u_role === 'super')
            {
              getCompanies()
            }else{
              console.log("company ID ", user.user.company_id)
              setCompanyID(user.user.company_id)
            }
           }
    
          }
        }
      }, [auth])

      const getCompanies = async() => {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-all-companies`).then((res)=>{
          if(res.data.status === 200)
          {
            setCompanies(res.data.data)
          }
        })
      }

      const optionClicked = async(value) =>{
        if(companyID === '')
        {
          return toast.error('Select a Company', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
        setShowLoadingSpinner(true)
        console.log("The report value options clicked is ", value)
        if(value === "transactions")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "productSales")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "proceeds")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "agentComms")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "exchanges")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "deposits")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "withdrawals")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }else if(value === "users")
        {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        }
        else if(value === "p_expire")
        {
          console.log("In here")
          setShowLoadingSpinner(false)
          setSelectedOption(value)
          SetDisplaySelection(false)
        }else{
            SetDisplaySelection(true)
            setShowLoadingSpinner(false)
        }
      }

      const displayAllSelection = async(value) =>{
        console.log("show everything again")
        setShowLoadingSpinner(false)
        SetDisplaySelection(true)
      }

      const companySelected = async(company) => {
        console.log("User selected: " + company)
        setCompanyID(company)
      }
     

  return (
    <div className='page-content'>
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>

        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Reports" breadcrumbItem="Reports" className="m-auto center" />
        <div>
            {showLoadingSpinner ?
                <Spinner type="grow" className="ms-2" color="secondary" />
                :

                <div>
                  {role === 'super' && displaySelection ?
                            <InputGroup className="mb-3">
                              <Label className="input-group-text">Select Company</Label>
                              <select
                                className="form-select"
                                // style={{ maxWidth: "90px" }}
                                onClick={(e) => {companySelected(e.target.value)}}
                              >
                                <option value="" >
                                  select
                                </option>
                                {(typeof companies !== "undefined" && companies.length > 0)? 
                                  companies.map((company, key) => (
                                    <option key={key} value={company.id}>{company.name}</option>
                                ))
                                : ''}
                              
                              </select>
                           
                            </InputGroup>

                            : ''}

                    {displaySelection ? 
                        <ReportSelection optionClicked={optionClicked} role={role} />
                    : 

                    <div>
                        {selectedOption === "transactions"  && companyID && companyID !== '' ?
                        <TransactionsReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                        : 
                        selectedOption === "productSales"  && companyID && companyID !== '' ?
                        <SalesReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                      : selectedOption === "proceeds" && companyID && companyID !== '' ?
                      <ProceedsReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                      : selectedOption === "agentComms" && companyID && companyID !== '' ?
                      <AgentCommsContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                    : selectedOption === "exchanges" && companyID && companyID !== '' ?
                    <ExchangesReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                  : selectedOption === "deposits" && companyID && companyID !== '' ?
                  <DepositCommsContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                : selectedOption === "withdrawals" && companyID && companyID !== '' ?
                <WithdrawReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
                : selectedOption === "p_expire" && companyID && companyID !== '' ?
                <ProductExpireReportContainer displayAllSelection={displayAllSelection} companyID={companyID} />
              : ''}

                    </div>}
                    </div>
            }
            </div>
        </Container>
    </div>
  )
}

export default ReportList