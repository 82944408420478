import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";


function ReferralHistory() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    
    const[email, setEmail] =  React.useState('');

    const[dateOptions, setDateOptions] =  React.useState([]);
    const[referralData, setReferralData] =  React.useState([]);
    const[referralHeader, setReferralHeader] =  React.useState([]);
    const[comanyID, setCompanyID] =  React.useState('');

    const[displayDownloadButton, setDisplayDownloadButton] =  React.useState(true);
    

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setEmail(user.user.email)
            getReferralDates()
                setUserid(user.user.userid);
                setCompanyID(user.user.company_id)
           }
    
          }
        }
      }, [auth])

      const getReferralDates = async() => {

        var dateObj = {
          company: comanyID
        }
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-referral-dates", dateObj).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
                await onReferralDateChanged('all')
                setDateOptions(res2.data.dates)
              
               
                // dateOptions.push(res2.data.dates)
            }
        })
      }

      const onReferralDateChanged = async(date) => {
        setDisplayDownloadButton(false)

        var dateObj = {
            date: date,
            company: comanyID
        }

        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-referral-dates-values", dateObj).then(async res2 =>{

            var headers = [
                { label: "Userid", key: "userid" },
                { label: "From User", key: "from_userid" },
                { label: "Amount", key: "amount" },
                { label: "Percentage", key: "percentage" },
                { label: "Info", key: "userinfo" },
                { label: "Date", key: "createdAt" },

              ];

              setReferralHeader(headers)

              console.log('data is ', res2.data)
      
            if(res2.data.status == 200)
            {
                setReferralData(res2.data.referrals)
                
                setDisplayDownloadButton(true)
            }
        })
      }  

  return (
    <div className='page-content'>
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>

        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
                

            <Row className="mt-4">
                <Col md="6" sm="12">
                    <label className="">Agent Proceeds Paid</label>
                    <select onChange={(e) => {onReferralDateChanged(e.target.value)}} className="form-control" id="referrals">
                        <option value="all">All</option>
                        {typeof dateOptions !== 'undefined' && dateOptions.length > 0 ?
                        dateOptions.map((date, key) => (
                            <option key={key} value={date}>{date}</option>
                        ))
                        : ''}
                    </select>

                    <br />
                    
                    {displayDownloadButton ?
                    <CSVLink
                    className="btn btn-primary rounded border-2-primary w-100"
                    data={referralData}
                    headers={referralHeader}
                    // onClick={e => {getDepositData(e.target.value)}}
                    >
                    Download CSV
                    </CSVLink>
                    : ''}
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ReferralHistory