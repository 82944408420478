import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Label,
    Input,
    Table,
} from "reactstrap";
import axios from 'axios';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

function DepositsByAmount() {
    const [minimumAmount, setMinimumAmount] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(5);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE}api/get-deposit-totals-by-amount`, {
                amount: Number(minimumAmount),
                currency: selectedCurrency,
                usersPerPage: usersPerPage,
                currentPage: currentPage
            });
         
            setData(response.data.data);
            setTotalUsers(response.data.total); // Assuming your response has a field called 'total'
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData(); // Call fetchData only when the search button is clicked
    };

    const downloadCSV = () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'deposits.csv');
        a.click();
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',');
        const rows = data.map(user => Object.values(user).join(','));
        return headers + '\n' + rows.join('\n');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Transactions" breadcrumbItem="Deposits By Amount" />

                    <Container>
                        <Row>
                            <Col>
                                <Form>
                                    <Label for="minimumAmount">Minimum Amount</Label>
                                    <Input type="number" id="minimumAmount" value={minimumAmount} onChange={(e) => setMinimumAmount(e.target.value)} />
                                    <Label for="currency">Currency</Label>
                                    <Input type="select" id="currency" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
                                        <option value="">All</option>
                                        <option value="1">USDT</option>
                                        <option value="2">ZAR</option>
                                        <option value="3">BTC</option>
                                    </Input>
                                    <Button onClick={handleSearch}>Search</Button>
                                    <Button onClick={downloadCSV}>Download CSV</Button>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>User ID</th>
                                            <th>Name</th>
                                            <th>Lastname</th>
                                            <th>Currency</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td>Loading...</td>
                                            </tr>
                                        ) : (
                                            data.map(user => (
                                                <tr key={user.userid}>
                                                    <td>{user.userid}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.lastname}</td>
                                                    <td>{user.currency === '1' ? "USDT" : user.currency === '2' ? "ZAR" : "BTC" }</td>
                                                    <td>{user.amount}</td>
                                                    <td>{user.date}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                                <div>
                                    <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
                                    <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * usersPerPage >= totalUsers}>Next</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DepositsByAmount;
