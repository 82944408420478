import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form,
  Label
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function CapitalPendingWithdraw() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Pending Withdraws');

    const[transactions, setTransactions] =  React.useState([]);
    
    const[allUsers, setAllUsers] =  React.useState([]);

    const[displayModal, setDisplayModal] =  React.useState(false);
    
    const[userTXID, setUserTXID] =  React.useState('');
    const[currentID, setCurrentID] =  React.useState('');
    const[withdrawalSum1, setWithdrawalSum1] =  React.useState(0);
    const[withdrawalSum2, setWithdrawalSum2] =  React.useState(0);
    const[withdrawalSum3, setWithdrawalSum3] =  React.useState(0);

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Name",
            accessor: "name",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Email",
            accessor: "email",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Currency",
            accessor: "currency",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.currency === '1' || cellProps.row.original.currency === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.currency === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
          {
            Header: "To Wallet",
            accessor: "w_address",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Country",
            accessor: "country",
            disableFilters: true,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Action",
            accessor: "id",
            disableFilters: true,
            Cell: cellProps => {
                return (
                    <div>
                    <button className="btn btn-rounded btn-success" onClick={(e) => {changeTXID(cellProps.value)}}
                    >Approve </button>
                    <button className="btn btn-rounded btn-danger" onClick={(e) => {declineDeposit(cellProps.value)}}
                    >Decline </button>
                              <button className="btn btn-rounded btn-primary" onClick={(e) => {setWithdrawalAsCapital(cellProps.value)}}
                    >Remove Caiptal Withdrawal </button>
                    </div>
                  );
            },
          },
    
        ],
        []
      );

      const setWithdrawalAsCapital = async(withdrawal) => {
        console.log("Withdrawal is: ", withdrawal)
        const withdrawalObj = {
          withdrawalID: withdrawal,
          capital_withdrawal: "false"
        }

        await axios
        .post(process.env.REACT_APP_BASE + "api/set-capital-withdrawal",withdrawalObj)
        .then(async (response) => {
          console.log("withdrawal response: ", response.data)
          if (response.data.status === 200) {
            await getUserDeposits()
            return toast.success('Updated Withdrawal Succesfful.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
        })
        .catch((error) => {
          console.log("error received: ", error)
          return toast.error('Unable to update withdrawal', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        });
      }

      const changeTXID = async(id) => {
        setDisplayModal(true)
        setCurrentID(id)
      }

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits()
    
          }
        }
      }, [auth])
    
    
      const approveDeposit = async () => {

        const updateDepObj = {
            transactionId: currentID,
            status: '1', 
            txid: userTXID
        }
        
        await axios.post(process.env.REACT_APP_BASE + "api/admin-update-withdrawal", updateDepObj).then((res)=>{
            console.log('updated deposits ', res.data)
            if(res.data.status == 200)
            {
              setUserTXID('')
              setCurrentID('')
                getUserDeposits()
             
            }else{
              setUserTXID('')
              setCurrentID('')
            }

            setDisplayModal(false)

            setTimeout(() => {window.location.reload(); }, 2000);
        });
      }

      const declineDeposit = async (id) => {

        const updateDepObj = {
            transactionId: id,
            status: '2', 
        
        }
        console.log('updated deposits 1 ')
        await axios.post(process.env.REACT_APP_BASE + "api/admin-update-withdrawal", updateDepObj).then((res)=>{
            console.log('updated deposits2 ', res.data)
            if(res.data.status == 200)
            {
                getUserDeposits()
            }
            console.log("it should now refresh")
            setTimeout(() => {window.location.reload(); }, 2000);
        });
      }

      const getUserDeposits = async () => {
        

        await axios.get(process.env.REACT_APP_BASE + "api/pending-withdrawals", {
          params: {
            capital_withdrawal: 'true',
          },
        }).then(async(res)=>{
            console.log('all withdrawals ', res.data)
            if(res.data.status === 200)
            {
                setTransactions(res.data.withdrawals)
                setAllUsers(res.data.users)
                await sumOfAllPendingWithdrawals(res.data.withdrawals)
            }else{
                setTransactions([])
            }
          
         
        });
      }

      const onCloseClick = () => {
        setUserTXID('')
        setCurrentID('')
        setDisplayModal(false)
      }

      const sumOfAllPendingWithdrawals = async(withdrawals) => {
        let sum1 = 0;
        let sum2 = 0;
        let sum3 = 0;

        for(const withdrawal of withdrawals) {

          if(withdrawal.currency === '1')
          {
            sum1 += withdrawal.amount;
          }

          if(withdrawal.currency === '2')
          {
            sum2 += withdrawal.amount;
          }

          if(withdrawal.currency === '3')
          {
            sum3 += withdrawal.amount;
          }
      
        }

        setWithdrawalSum1(sum1)
        setWithdrawalSum2(sum2)
        setWithdrawalSum3(sum3)

        return;
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />

  {displayModal ?
      <Modal isOpen={true} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h4>Enter TXID (Leave Blank if not applicable)</h4>
              <input type="text" value={userTXID} onChange={(e) => {setUserTXID(e.target.value)}} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={approveDeposit}
              >
                Approve
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
    : ''}
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
  <Form>
    <Col md={4}>
        <Label>Total Withdrawals USDT:</Label>
        <Input disabled className="form-control bg-secondary text-light" placeholder="test" value={withdrawalSum1.toFixed(2)} />
    </Col>
    <br />
    <Col md={4}>
      <Label>Total Withdrawals ZAR:</Label>
      <Input disabled className="form-control bg-secondary text-light" placeholder="test" value={withdrawalSum2.toFixed(2)} />
    </Col>
    <br />
    <Col md={4}>
      <Label>Total Withdrawals BTC:</Label>
      <Input disabled className="form-control bg-secondary text-light" placeholder="test" value={withdrawalSum3.toFixed(6)} />
    </Col>

  </Form>
  <Col md="12" sm="12">
        <UserSingleTable tableData={transactions} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CapitalPendingWithdraw

