import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form,
  Label,
  Select
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function AllWithdrawals() {
    
    const{auth,user} = React.useContext(UserContext)
    
    const[selectedStatus, setSelectedStatus] =  React.useState('');

    const[selectedCurrency, setSelectedCurrency] =  React.useState('');
    const[agents, setAgents] =  React.useState([]);

    const [data, setData] = useState([]); // Store user data
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [searchTerm, setSearchTerm] = useState(""); // Search term
    const [searchName, setSearchName] = useState("");
    const [companyID, setCompanyID] = useState(""); // Search term

    const [totalWithdrawals, setTotalWithdrawals] = useState(0);
    const [paginatedUsers, setPaginatedUsers] = useState([]);
    const [selectedDate, setSelectedDate] = useState('false');
    const [usersPerPage, setUsersPerPage] = useState(5);
    const [userIds, setUserIds] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
            fetchAllMainAgents();
            // getAllWithdrawals(currentPage)
          }
        }
      }, [auth])

      useEffect(() => {
        console.log("Search term: ", searchTerm)
        setDisplayLoader(true)
        axios
          .get(process.env.REACT_APP_BASE + "api/all-withdrawals", {
            params: {
              usersPerPage,
              searchTerm,
              companyID,
              currentPage,
              selectedStatus,
              selectedCurrency,
              selectedDate
            },
          })
          .then((response) => {
            console.log("response: ", response.data);
            if (response.data.status === 200) {
              setData(response.data.withdrawals);
              setTotalWithdrawals(response.data.total); // Set the total count
              var newFilteredUsers = response.data.withdrawals
       
              setPaginatedUsers(newFilteredUsers)
            
            }
            setDisplayLoader(false)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, [currentPage, searchTerm, usersPerPage, selectedStatus, selectedCurrency, selectedDate, companyID]);
      
      // Calculate the index range of users to display on the current page
      const startIndex = (currentPage - 1) * usersPerPage;
      const endIndex = startIndex + usersPerPage;
      
      // Filter users based on the search term
      const filteredUsers = data.filter((user) =>
      {
 
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      }

      );

      const changingCompanyID = async(company) => {
        console.log("comapny ID: ", company)
        setCompanyID(company);
      }

      const fetchAllMainAgents = async() => {
        var adminObj = {
            role: 'admin'
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-all-admins`, adminObj).then((res)=>{
            if(res.data.status === 200)
            {
              console.log("main agents: ", res.data.response)
                setAgents(res.data.response)
                
            }
          })
    }

        // Function to handle searchName change
      const handleSearchNameChange = (value) => {
        setSearchName(value);

        // Call the API to get user IDs based on searchName
        axios
          .get(process.env.REACT_APP_BASE + "api/get-userid-by-name", {
            params: {
              searchName: value,
            },
          })
          .then((response) => {
            console.log("Name response: ", response.data)
            if (response.data.status === 200) {
              setUserIds(response.data.userData);
            }
          })
          .catch((error) => {
            console.error("Error fetching user IDs:", error);
          });
      };

      // Function to set the selected userid and search term
      const handleUserSelect = (selectedUserId) => {
        setSearchTerm(selectedUserId); // Set the selected userid as the search term
        // setUserIds([]); // Clear the dropdown results
      };  
          

      const onFilterStatusChange = async (status) => {
        setSelectedStatus(status);
        setCurrentPage(1); // Reset page to 1 when changing filters
      };
      
      const onCurrencyChange = async (currency) => {
        setSelectedCurrency(currency);
        setCurrentPage(1); // Reset page to 1 when changing filters
      };
      
      const onDatesChanged = async (date) => {
        setSelectedDate(date);
        setCurrentPage(1); // Reset page to 1 when changing filters
      };

      // Calculate the index range of users to display on the current page
      // const startIndex = (currentPage - 1) * usersPerPage;
      // const endIndex = startIndex + usersPerPage;

      // // Filter users based on the search term
      // const filteredUsers = data.filter(user =>
      //   user.name.toLowerCase().includes(searchTerm.toLowerCase())
      // );

      // Paginate the filtered users


      // Function to handle page navigation
      const handlePageChange = async(newPage) => {
        setCurrentPage(newPage);
        // await getAllWithdrawals(newPage)
      };

      const handleClearFilter = async() => {
        setSelectedDate('');
        setSelectedCurrency('');
        setSelectedStatus('');
        setCurrentPage(1);
        setSearchTerm('')
        setSearchName('')
      }

      const markAsCapitalWithdrawal = async(withdrawalID) => {

        const withdrawalObj = {
          withdrawalID: withdrawalID,
          capital_withdrawal : 'true'
        }

        await axios
        .post(process.env.REACT_APP_BASE + "api/set-capital-withdrawal",withdrawalObj)
        .then(async (response) => {
          console.log("withdrawal response: ", response.data)
          if (response.data.status === 200) {
            await reloadUsersData()
            return toast.success('Updated Withdrawal Succesfful.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
        })
        .catch((error) => {
          console.log("error received: ", error)
          return toast.error('Unable to update withdrawal', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        });
      }

      const reloadUsersData = async() => {
        await axios
          .get(process.env.REACT_APP_BASE + "api/all-withdrawals", {
            params: {
              usersPerPage,
              searchTerm,
            
              currentPage,
              selectedStatus,
              selectedCurrency,
              selectedDate
            },
          })
          .then((response) => {
            console.log("response: ", response.data);
            if (response.data.status === 200) {
              setData(response.data.withdrawals);
              setTotalWithdrawals(response.data.total); // Set the total count
              var newFilteredUsers = response.data.withdrawals
       
              setPaginatedUsers(newFilteredUsers)
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }



  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transactions" breadcrumbItem="All Withdrawals" />

          <Row className="mb-3">
          <Form>
        <div className="border border-dark p-2">
          <h5 className="text-md text-underline">Get Userid:</h5>
          <Col className="mt-2" md={12}>
            <Label>Search By Name (Use this userid to find withdrawals):</Label>
            <input
              type="text"
              placeholder="Name"
              className="form-control"
              value={searchName}
              onChange={(e) => handleSearchNameChange(e.target.value)}
            />
            {/* Display dropdown if user IDs are available */}
            {userIds && userIds.length > 0 ? (
              <div className="mt-2">
                {userIds.map((user) => (
                  <button
                    key={user.userid}
                    className={`btn ${searchTerm === user.userid ? 'btn-success' : 'btn-primary'} m-1`}
                    onClick={(e) => { e.preventDefault();
                      handleUserSelect(user.userid)}}
                  >
                    {user.name + " " + user.lastname}
                  </button>
                ))}
              </div>
            ) : ''}

          </Col>
        </div>
      </Form>
          </Row>
          <Row>
            <Form>
              <div className="border border-dark p-2">
                <h5 className="text-md text-underline">Filter By:</h5>
                <Col className="mt-2" md={12}>
                    <Label>Status:</Label>
                    <select value={selectedStatus} onChange={(e) => onFilterStatusChange(e.target.value)} className="form-control ">
                      <option value="">All</option>
                      <option value={'0'}>Pending</option>
                      <option value={'1'}>Approved</option>
                      <option value={'2'}>Declined</option>
                    </select>
                </Col>

                <Col className="mt-2" md={12}>
                    <Label>Currency:</Label>
                    <select value={selectedCurrency} onChange={(e) => onCurrencyChange(e.target.value)} className="form-control ">
                      <option value="">All</option>
                      <option value={'1'}>USDT</option>
                      <option value={'2'}>ZAR</option>
                      <option value={'3'}>BTC</option>
                    </select>
                </Col>

                <Col className="mt-2" md={12}>
                    <Label>Dates:</Label>
                    <select value={selectedDate} onChange={(e) => onDatesChanged(e.target.value)} className="form-control ">
                      <option value={'false'}>Old To New</option>
                      <option value={'true'}>New To Old</option>

                    </select>
                </Col>

                <Col className="mt-2" md={12}>
                    <Label>Search By Userid:</Label>
                    <input
                      type="text"
                      placeholder="Userid"
                      className="form-control"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Col>

                  <Col className="mt-2" md={12}>
                    <Label>Search By CompanyID:</Label>
                    <select
                        value={companyID}
                        className="form-select"
                        onChange={(e) => {
                            changingCompanyID(e.target.value);
                        }}
                    >
                        <option value="">Show All</option>
                        {typeof agents !== "undefined" && agents.length > 0
                            ? agents.map((agent, key) => (
                                  <option key={key} value={agent.company_id}>
                                      {agent.name}
                                  </option>
                              ))
                            : ''}
                    </select>
                  </Col>



                <Col className="mt-2 mb-2" md={3}>
                    <button className="btn btn-danger form-control" onClick={(e) => {e.preventDefault();
                      handleClearFilter()}}>Clear Filter</button>
                </Col>
              </div>
            </Form>
            <Col md="12" sm="12">

            </Col>
          </Row>

          <Row className="mt-3">
            <Form>
              <div className=" p-2">
                <div>
 

                    <Col className="mt-2" md={6}>
                    <Label>Select Total Results to Display:</Label>
                    <select value={usersPerPage} onChange={(e) => setUsersPerPage(e.target.value)} className="form-control ">
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={70}>70</option>

                    </select>
                  </Col>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Currency</th>
                        <th>Amount</th>
                        <th>To Wallet</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {displayLoader ?
                      <tr >
                        <td>Loading...</td>
                      </tr>
                               :
                      paginatedUsers.map((user) => (
                        <tr key={user.id}>
                          <td>{user.userid}</td>
                          <td>{user.name}</td>
                          <td>{user.status === '1' ? 'Approved' : user.status === '0' ? 'Pending' : 'Declined'}</td>
                          <td>{user.currency === '1' ? "USDT" : user.currency === '2' ? 'ZAR' : 'BTC'}</td>
                          <td>{user.amount}</td>
                          <td>{user.w_address}</td>
                          <td>{user.updatedAt}</td>
                          <td>{user.capital_withdrawal === 'false' && user.status === '0' ? <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); markAsCapitalWithdrawal(user.id)}}>Capital Withdrawal</button> : '---'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent page refresh
                      handlePageChange(currentPage - 1);
                    }}
                    disabled={currentPage === 1 || searchTerm !== ''}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent page refresh
                      handlePageChange(currentPage + 1);
                    }}
                    disabled={usersPerPage > totalWithdrawals || searchTerm !== ''}
                  >
                    Next
                  </button>
                  </div>
                </div>
              </div>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllWithdrawals

