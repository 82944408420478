import React, { useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminDepositContracts() {
  const { auth } = React.useContext(UserContext);
  const [selectedItem, setSelectedItem] = useState(0);
  const [itemSelected, setItemSelected] = useState(false);
  const [useridInput, setUseridInput] = useState("");
  const [possibleUsers, setPossibleUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currency, setSelectedCurrency] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState(0);

  useEffect(() => {
    if (auth != null) {
      if (!auth) {
        window.location.href = "/login";
      } else {
        // Perform any other necessary actions for authenticated users
      }
    }
  }, [auth]);

  const handleUseridInputChange = (e) => {
    setUseridInput(e.target.value);
  };

  const handleSearchClick = async () => {
    // Call the findPossibleUsers function with the entered userid
    await findPossibleUsers(useridInput);
  };

  const handleUserSelection = (user) => {
    // Handle user selection from the dropdown
    setSelectedUser(user);
    setItemSelected(true);
  };

  const findPossibleUsers = async (possibleUserid) => {
    var possibleUserObj = {
      userid: possibleUserid,
    };

    await axios.post(process.env.REACT_APP_BASE + "api/find-possible-users", possibleUserObj)
      .then((res) => {
      
        if (res.data.status === 200) {
          // Set the possible users in the state
          console.log("results returned: ", res.data);
          setPossibleUsers(res.data.users);
        } else {
          // No results found
          setPossibleUsers([]);
        }
      });
  };

  const sendContractForm = async() => {

    console.log(selectedUser)
    

    var zarAmount = currency == 'zar' ? 'ZAR ' + enteredAmount : "N/A";

    var emailObj = {
        userid: selectedUser,
        currency: currency.toUpperCase(),
        amount: enteredAmount,
        zar_amount: zarAmount
    }
     
    var sendDeositEmail = await axios.post(process.env.REACT_APP_BASE + "api/send-deposit-contract",emailObj).then(async res =>{});

    setTimeout(() => {window.location.reload(); }, 2000);    

  }

  return (
    <React.Fragment>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
      <div className="page-content">
        <div className="container-fluid">
            <div>
                <h3>Admin Deposit Contracts</h3>
                <div className="mb-3">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Enter userid"
                    value={useridInput}
                    onChange={handleUseridInputChange}
                    />
                    <button className="btn btn-primary ml-2" onClick={handleSearchClick}>
                    Search
                    </button>
                </div>

                <div className="form-control">
                    <div className="label-text">Selected User</div>
                    <select
                    className="form-control"
                    value={selectedUser ? selectedUser.userid : null}
                    onChange={(e) => {
                        console.log(e.target.value);
                        handleUserSelection(e.target.value);
                    }}
                    >
                        <option className="dropdown-item" value="">
                         Select User
                        </option>
                    {possibleUsers && possibleUsers.length > 0 && possibleUsers.map((user) => (
                        <option key={user.id} value={user.userid}>
                        {user.userid}
                        </option>
                    ))}
                    </select>

                    <div className="label-text">Currency</div>
                    <select
                    className="form-control"
                    value={currency}
                    onChange={(e) => {
                        setSelectedCurrency(e.target.value);
                    }}
                    >
                        <option className="dropdown-item" value="">
                         Select Currency
                        </option>
                    <option className="dropdown-item" value="zar">
                        ZAR
                    </option>
                    <option className="dropdown-item" value="nad">
                        NAD
                    </option>
                    <option className="dropdown-item" value="usdt">
                        USDT
                    </option>
                    <option className="dropdown-item" value="btc">
                        BTC
                    </option>
                    </select>

                    <div className="label-text">Amount</div>
                    <input
                    type="amount"
                    step="0.01"
                    value={enteredAmount}
                    onChange={(e) => {
                        setEnteredAmount(e.target.value);
                    }}
                    />
                    <br />

                    <div className="mt-3">
                        <button className="btn btn-success" onClick={() => sendContractForm()}>
                            Send Contract
                        </button>
                    </div>
           
                </div>
                </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminDepositContracts;
