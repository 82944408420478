import React, { useEffect, useState, useMemo } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";
import TableContainer from "components/Common/TableContainer";
import { BillingName } from "pages/Dashboard/LatestTranactionCol";


function AdminOnboardingFee() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    
    const[email, setEmail] =  React.useState('');
    const[comanyID, setCompanyID] =  React.useState('');
    const[loadingSpinner, setLoadingSpinner] =  React.useState(true);
    const[onboardingData, setOnboardingData] =  React.useState(null);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);


    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setEmail(user.user.email)
            getOnboardingFees(user.user.userid)
                setUserid(user.user.userid);
                setCompanyID(user.user.company_id)
           }
    
          }
        }
      }, [auth])

      const getOnboardingFees = async(userid) => {

        var dateObj = {
          userid: userid,
          page: currentPage
        }
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-admin-onboarding-fee", dateObj).then(async res2 =>{
            // console.log(res2.data)
            if(res2.data.status == 200)
            {
              console.log(res2.data.response)
                // await onReferralDateChanged('all')
                setOnboardingData(res2.data.response)
              
            }

            setLoadingSpinner(false)
        })
      }

      const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "From User",
            accessor: "user",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Transaction",
            accessor: "transaction_id",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Currency",
            accessor: "currency",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
            filterable: false,
            Cell: cellProps => {
              return <p>{cellProps.value === '0' ? 'Pending' : cellProps.value === '1' ? 'Active' : cellProps.value === '2' ? 'Declined' : ''}</p>;
          },
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            Cell: cellProps => {
                return <BillingName {...cellProps} />;
            },
          },

        ],
        []
      );

      const handleNextPageClick = async () => {
        setCurrentPage(currentPage + 1); // Increment the page number
        setOnboardingData([]); // Clear existing data
        await getOnboardingFees(userid); // Fetch the next page
      };

      const handlePrevPageClick = async () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1); // Decrement the page number
          setOnboardingData([]); // Clear existing data
          await getOnboardingFees(userid); // Fetch the previous page
        }
      };

  return (
    <div className='page-content'>
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>

        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Onboarding" breadcrumbItem="Onboarding" />


                
            <Row className="mt-4">
                <Col md="12" sm="12">
                    {
                    loadingSpinner?
                    <Spinner type="grow" className="ms-2" color="secondary" />
                 : 
                    onboardingData ?
                    <>
                    <ReactTable
                      data={onboardingData}
                      columns={columns}
                      pageSize={10}
                      className="-striped -highlight"
                    />
                      <button className="btn btn-warning" onClick={handlePrevPageClick}>Previous Page</button>
                    <button className="btn btn-success" onClick={handleNextPageClick}>Next Page</button>
                  </>
                    : 'No Data Found!'}
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AdminOnboardingFee