import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function DisplayMultipleUploads({ value, onClickFunction, uploads }) {
    const [imageSrcs, setImageSrcs] = useState([]);

    if (uploads && typeof uploads === 'string') {
    
        uploads = JSON.parse(uploads);

    }
  
    useEffect(() => {

      console.log("Uploads:", uploads,Array.isArray(uploads) && uploads.length > 0 && imageSrcs.length !== uploads.length)
      if (Array.isArray(uploads) && uploads.length > 0 && imageSrcs.length !== uploads.length) {
        fetchImageSrcs();
        console.log("Called images")
      }
    }, [uploads, imageSrcs.length]);

    const fetchImageSrcs = async () => {
        const srcs = [];
        console.log("Uploads1: ", uploads)
        for (const upload of uploads) {
            console.log("Now upload...: ", upload)
          if (!upload.includes('.png') && !upload.includes('.jpeg') && !upload.includes('.jpg')) {
            srcs.push('');
          } else {
            try {
              const res = await axios.post(process.env.REACT_APP_API_BASE_URL + 'getimage', { image: upload });
              console.log("Finally: ", res.data)
              srcs.push(res.data);
            } catch (error) {
              console.error('Error fetching image:', error);
              srcs.push('');
            }
         }
        }
        setImageSrcs(srcs);
      };
  
    return (
      <div>
        {Array.isArray(uploads) && uploads.length > 0 &&
          uploads.map((upload, index) => (
            <div key={index}>
              <button value={value} onClick={(e) => onClickFunction(value)} style={{ padding: "unset", border: "unset" }}>
                <i className="fas fa-times-circle bg-red" style={{ fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px" }}></i>
              </button>
        
              {!upload.includes('.png') && !upload.includes('.jpeg') && !upload.includes('.jpg') ?
                <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/' + upload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                :
                <Zoom><img src={imageSrcs[index]} className="w-25 img-thumbnail" alt={`Upload ${index}`} /></Zoom>}
            </div>
          ))
        }
      </div>
    );
  }
  
  export default DisplayMultipleUploads;
  
