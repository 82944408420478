import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";


import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../LatestTranactionCol";

import TableContainer from "../../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

  const FilterTable = ({users}) => {


  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
console.log(users)

const columns = useMemo(
    () => [
      {
        Header: "Userid",
        accessor: "userid",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Username",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Role",
        accessor: "u_role",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Company",
        accessor: "company_info",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          console.log(cellProps.value)
          
                    return (
                      <div> <h6>{cellProps.value.name}</h6>
            </div>
          );
        },
      },
      // {
      //   Header: "Date",
      //   accessor: "updatedAt",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //       return <Date {...cellProps} />;
      //   },
      // },
      {
        Header: "View UserInfo",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          // return <PaymentMethod {...cellProps} />;
                    return (
            <Button className="btn btn-round btn-success" onClick={(e) => {editUserBtn(cellProps.value)}} >
              Edit User
            </Button>
          );
        },
        // Header: "View Details",
        // disableFilters: true,
        // accessor: "view",
        // Cell: cellProps => {
        //   return (
        //     <Button
        //       type="button"
        //       color="primary"
        //       className="btn-sm btn-rounded"
        //       onClick={toggleViewModal}
        //     >
        //       View Details
        //     </Button>
        //   );
        // },
      },
    ],
    []
  );

  const editUserBtn = async(id) => {
    console.log("Clicked Button " + id)
    window.location.href="/edit-admin/" + id
  }
  return (
    <React.Fragment>
  
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">User Info</div>
          <TableContainer
            columns={columns}
            data={users}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FilterTable