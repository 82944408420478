import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function ExchangeHistory() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Exchange History');

    const[transactions, setExchanges] =  React.useState([]);
    

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Amount To Wallet",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                // return <BillingName {...cellProps} />;
                return (
                  <p>
                    {parseFloat(cellProps.value).toFixed(2)}
                  </p>
                );
            },
          },
          {
            Header: "Fee",
            accessor: "fee",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                // return <BillingName {...cellProps} />;
                return (
                  <p>
                    {parseFloat(cellProps.value).toFixed(2)}
                  </p>
                );
            },
          },
          {
            Header: "Before Deduction",
            accessor: "exchange_amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
                // return <BillingName {...cellProps} />;
                return (
                  <p>
                    {parseFloat(cellProps.value).toFixed(2)}
                  </p>
                );
            },
          },
          {
            Header: "Amount From",
            accessor: "amount_from",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
          {
            Header: "Amount To",
            accessor: "amount_to",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
          {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'Approved' : cellProps.value == '2' ? 'Declined' : 'Pending'}
                </p>
              );
            },
          },
          {
            Header: "Date",
            accessor: "createdAt",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value}
                </p>
              );
            },
          },
    
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits()
    
          }
        }
      }, [auth])
    
      const approveDeposit = async (id) => {

        var depositObj = {
            exchangeid: id,
        }
    
        await axios.post(process.env.REACT_APP_BASE + "api/handle-new-exchange", depositObj).then(async (res)=>{
            
            await getUserDeposits()

          
        });
      }

      const declineDeposit = async (id) => {

        var depositObj = {
            depositObj: id,
        }
    
        await axios.post(process.env.REACT_APP_BASE + "api/decline-new-exchange", depositObj).then(async (res)=>{
            
            if(res.data.status === 200)
            {
                await getUserDeposits()
            }
           

          
        });
      }

      const getUserDeposits = async () => {
        
        await axios.post(process.env.REACT_APP_BASE + "api/get-admin-exchange-history").then(async (res)=>{
            
          setExchanges(res.data.exchanges)

          
        });
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
  <Col md="12" sm="12">
        <UserSingleTable tableData={transactions} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExchangeHistory




