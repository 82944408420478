import React from "react";
import { Row, Col, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const EmailTemplateBilling = props => {
   //meta title
   document.title="Billing Email | Skote - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">

      </div>
    </React.Fragment>
  );
};

export default EmailTemplateBilling;
