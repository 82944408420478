import React from 'react'

import { CSVLink, CSVDownload } from "react-csv";

function NewCSVLink({name, data, headers}) {
  return (
    <div>                    <CSVLink
    filename={name}
    className="btn btn-primary rounded border-2-primary w-100"
    data={data}
    headers={headers}
    >
    Deposits
    </CSVLink></div>
  )
}

export default NewCSVLink