import React, { useEffect, useRef } from "react";


import { UserContext } from "../../UserContext";
import axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Spinner
} from "reactstrap";
import FilterTable from "pages/Dashboard/Tables/Admin/FilterTable";
import ReportSelection from "./ReportContainer/ReportSelection";
import ReportContainer from "./ReportContainer/ReportContainer";
function AdminAgentReport() {

    const{auth,user} = React.useContext(UserContext)
    const[selectedItem, setSelectedItem] =  React.useState(0);
    const[itemSelected, setItemSelected] =  React.useState(false);
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
          
        }
      }
    }, [auth])

    const onItemClick = async(item) => {
      setSelectedItem(item);
      setItemSelected(true)
    }

    const backClick = async() => {
      setItemSelected(false)
      setSelectedItem(0);
    }

    

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Admin" breadcrumbItem="Agent Reports" />

          {!itemSelected?
        
          <Row>
       
              {/* View all agent and agent data like sub agents etc */}
              <ReportSelection title="Agent And Sub Agents" body="View All Agents sub agents" productValue={1} onItemClick={onItemClick} />

              {/* View all users under agent */}
              <ReportSelection title="Agent users" body="View All Agent and sub agent users" productValue={2} onItemClick={onItemClick} />

              {/* View all agent revenue data */}
              <ReportSelection title="Agent Revenues" body="View All Agents and sub agents revenue" productValue={3} onItemClick={onItemClick} />
          </Row>

          : 
          <Row>
            <ReportContainer backClick={backClick} selectedItem={selectedItem} />
          </Row>
          }

        </div>
    </div>
  </React.Fragment>
  )
}

export default AdminAgentReport