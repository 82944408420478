import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function PendingBankConfirmation() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Pending Bank Confirmation');

    const[transactions, setTransactions] =  React.useState([]);
    
    const[allBankConfirmations, setBankConfirmations] =  React.useState([]);

    const[displayModal, setDisplayModal] =  React.useState(false);
    
    const[declineNote, setDeclineNote] =  React.useState('');
    const[currentID, setCurrentID] =  React.useState('');
    


    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Name",
            accessor: "name",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Bank Account Name",
            accessor: "bank_account_name",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Bank Name",
            accessor: "bank_bank_name",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Bank Account Type",
            accessor: "bank_account_type",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Bank Account Number",
            accessor: "bank_account_number",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },

         
          {
            Header: "Confirmation Upload",
            accessor: "bank_upload",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              const [imageUrl, setImageUrl] = useState('');
          
              useEffect(() => {
                async function fetchImageUrl() {
                  const poa = await getUploadImage(cellProps.value);
          
                  if (poa) {
                    setImageUrl(poa);
                  }
                }
                fetchImageUrl();
              }, [cellProps.value]);
          
              return (
                <div>
                  {cellProps.value && !cellProps.value.includes('.png') && !cellProps.value.includes('.jpeg') && !cellProps.value.includes('.jpg')
                    ? <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+ cellProps.value} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                    : <Zoom><img src={imageUrl} className="w-50 img-thumbnail"/></Zoom>}
                </div>
              );
            },
          },
         
          {
            Header: "Action",
            accessor: "id",
            disableFilters: true,
            Cell: cellProps => {
                return (
                    <div>
                    <button className="btn btn-rounded btn-success" onClick={(e) => {approveBankConfirmation(cellProps.value)}}
                    >Approve </button>
                    <button className="btn btn-rounded btn-danger" onClick={(e) => {openDeclineModal(cellProps.value)}}
                    >Decline </button>
                    </div>
                  );
            },
          },
    
        ],
        []
      );

      const openDeclineModal = async(id) => {
        setDisplayModal(true)
        setCurrentID(id)
      }

      const getUploadImage = async(image) => {
        console.log("image: " + image)
        var poa = await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, {image: image})
        .then(res2 => {
           
          return res2.data;
        })
        console.log(poa)
        return poa;
      }

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getAllPendingBankConfirmation()
    
          }
        }
      }, [auth])
    
    
      const approveBankConfirmation = async(id) => {
        const approveObj = {
            id: id,
            status: 1
          }
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}pending-upload-result`,approveObj).then((res)=>{
            if(res.data.status === 200)
            {
                toast.success('Approved Successfull', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                toast.error('Unable To Approve', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
          })

          await getAllPendingBankConfirmation()
      }

      const declineBankConfirmation = async(id) => {
        const approveObj = {
            id: id,
            status: 2,
            note: declineNote
          }
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}pending-upload-result`,approveObj).then((res)=>{
            if(res.data.status === 200)
            {
                toast.success('Declined Successfull', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                toast.error('Unable To Decline', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
          })
          await getAllPendingBankConfirmation()
          setDisplayModal(false)
        }

      const getAllPendingBankConfirmation = async () => {
        

        await axios.post(process.env.REACT_APP_BASE + "api/pending-bank-confirmation").then((res)=>{
     
            if(res.data.status === 200)
            {
                setBankConfirmations(res.data.findPendingBank)
            }else{
                setBankConfirmations([])
            }
          
         
        });
      }

      const onCloseClick = () => {
        setDeclineNote('')
        setCurrentID('')
        setDisplayModal(false)
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />


      <Modal isOpen={displayModal} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h4>Please enter the reason for declining?</h4>
              <textarea style={{width: "100%"}} value={declineNote} onChange={(e) => {setDeclineNote(e.target.value)}} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={(e) => {declineBankConfirmation(currentID)}}
              >
                Decline
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>

<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
    
  <Col md="12" sm="12">
        <UserSingleTable tableData={allBankConfirmations} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingBankConfirmation

