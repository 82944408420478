import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Input, Label, Row } from "reactstrap";
import logodark from "../../assets/images/AlphaX_Logo.png";
import CarouselPage from "./CarouselPage";
import { useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import * as Yup from 'yup';

const Login2 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const validation = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    }
  });

  const loginFunc = async () => {
    const userData = {
      username: email,
      password: password,
      isAdmin: true
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login-admin`, userData, { withCredentials: true });
      if (res.data.status === 200 && res.data.auth) {
        toast.success('Successfully Logged In!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => { window.location.href = "/dashboard" }, 2000);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div style={{ backgroundImage: "linear-gradient(to right top, #241b2c, #453541, #655258, #837373, #a09592)", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Container>
          <Row className="g-0">
            <Col xl={{ size: 4 }} className="m-auto">
              <div className=" p-md-5 p-4" style={{ background: "#ffffff", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/dashboard" className="d-block auth-logo">
                      <img
                        src={logodark}
                        alt=""
                        className="auth-logo-dark"
                        style={{ width: "150px" }}
                      />
                    </Link>
                  </div>
                  <div className="my-auto">
                    <Form onSubmit={validation.handleSubmit}>
                      <div className="mb-3">
                        <Label className="form-label" style={{ color: "#A09592" }}>Email</Label>
                        <Input
                          name="email"
                          value={email}
                          type="email"
                          placeholder="Enter Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {validation.errors.email && <div className="text-danger">{validation.errors.email}</div>}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" style={{ color: "#A09592" }}>Password</Label>
                        <Input
                          name="password"
                          value={password}
                          type="password"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {validation.errors.password && <div className="text-danger">{validation.errors.password}</div>}
                      </div>
                      <div className="form-check mb-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="auth-remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                          style={{ color: "#A09592" }}
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn new-authentication-button btn-block"
                          type="submit"
                          onClick={loginFunc}
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0" style={{ color: "#A09592" }}>
                      © {new Date().getFullYear()} AlphaX Green Admin.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login2;
