import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const InvestmentTable = ({ investmentData }) => {
  const [searchText, setSearchText] = useState('');

  // Columns configuration for the table
  const columns = [
    { dataField: 'userId', text: 'User ID' },
    { dataField: 'name', text: 'Name' },
    { dataField: 'usdtDeposits', text: 'USDT Deposits' },
    { dataField: 'zarDeposits', text: 'ZAR Deposits' },
    { dataField: 'btcDeposits', text: 'BTC Deposits' },
    { dataField: 'usdtWithdrawals', text: 'USDT Withdrawals' },
    { dataField: 'zarWithdrawals', text: 'ZAR Withdrawals' },
    { dataField: 'btcWithdrawals', text: 'BTC Withdrawals' },
    { dataField: 'usdtProductSales', text: 'USDT Product Sales' },
    { dataField: 'zarProductSales', text: 'ZAR Product Sales' },
    { dataField: 'btcProductSales', text: 'BTC Product Sales' },
    { dataField: 'usdtTransactionDeposits', text: 'USDT Transaction Deposits' },
    { dataField: 'zarTransactionDeposits', text: 'ZAR Transaction Deposits' },
    { dataField: 'btcTransactionDeposits', text: 'BTC Transaction Deposits' },
    { dataField: 'usdtTransactionWithdrawals', text: 'USDT Transaction Withdrawals' },
    { dataField: 'zarTransactionWithdrawals', text: 'ZAR Transaction Withdrawals' },
    { dataField: 'btcTransactionWithdrawals', text: 'BTC Transaction Withdrawals' }
  ];

  // Transform investment data into rows for the table
  const rows = Object.entries(investmentData).map(([userId, userData]) => ({
    userId,
    name: userData.userInfo?.name,
    usdtDeposits: userData.deposits?.['1'] ?? 0,
    zarDeposits: userData.deposits?.['2'] ?? 0,
    btcDeposits: userData.deposits?.['3'] ?? 0,
    usdtWithdrawals: userData.withdrawals?.['1'] ?? 0,
    zarWithdrawals: userData.withdrawals?.['2'] ?? 0,
    btcWithdrawals: userData.withdrawals?.['3'] ?? 0,
    usdtProductSales: userData.productSales?.['1'] ?? 0,
    zarProductSales: userData.productSales?.['2'] ?? 0,
    btcProductSales: userData.productSales?.['3'] ?? 0,
    usdtTransactionDeposits: userData.transactionDeposits?.['1'] ?? 0,
    zarTransactionDeposits: userData.transactionDeposits?.['2'] ?? 0,
    btcTransactionDeposits: userData.transactionDeposits?.['3'] ?? 0,
    usdtTransactionWithdrawals: userData.transactionWithdrawals?.['1'] ?? 0,
    zarTransactionWithdrawals: userData.transactionWithdrawals?.['2'] ?? 0,
    btcTransactionWithdrawals: userData.transactionWithdrawals?.['3'] ?? 0
  }));

  // Filtered investment data based on search text
  const filteredData = rows.filter(row => 
    row.userId.toLowerCase().includes(searchText.toLowerCase()) ||
    (row.name && row.name.toLowerCase().includes(searchText.toLowerCase()))
  );

  // Function to handle CSV export
  const handleExportCSV = () => {
    const header = columns.map(col => col.text); // Extract column headers
    const csv = [
      header.join(','), // Join headers into CSV string
      ...filteredData.map(row => 
        header.map(field => row[field.dataField]).join(',')
      )
    ].join('\n'); // Join rows into CSV string

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'investment_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container fluid>
      <h2>Investment Table</h2>
      <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Search by User ID or Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Form.Group>
      {/* <Form.Group controlId="exportButton">
        <button className="btn btn-primary" onClick={handleExportCSV}>
          Export to CSV
        </button>
      </Form.Group> */}
      <BootstrapTable
        keyField='userId'
        data={filteredData}
        columns={columns}
        striped
        hover
        responsive
        pagination={paginationFactory({})}
      />
    </Container>
  );
};

export default InvestmentTable;
