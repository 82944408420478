import React, { useEffect, useRef } from "react";


import { UserContext } from "../../UserContext";
import axios from 'axios'


import FilterTable from "pages/Dashboard/Tables/Admin/FilterTable";
import MyAgentTable from "pages/Dashboard/Tables/Agents/MyAgentTable";
function MyAgents() {

    const{auth,user} = React.useContext(UserContext)
    const[users, setUsers] =  React.useState([]);
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
          getUsers(user.user)
        }
      }
    }, [auth])

    const getUsers = async(user) => {
        const userData = {
            userid: user.userid,
          }
      
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}get-my-agents`,userData).then((res)=>{
            console.log(res.data.agents)
            if(res.data.status == 200)
            {
                setUsers(res.data.agents)
              
            }
      
          })
    }
  

  return (
    <div>
        <MyAgentTable users={users} />
    </div>
  )
}

export default MyAgents