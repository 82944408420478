import RedirectBack from "components/Containers/RedirectBack";
import React, { useEffect, useRef } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner,
    InputGroup,
    Label
  } from "reactstrap";
import ReportSelection from "./ReportSelection";

import axios from 'axios'

import { CSVLink, CSVDownload } from "react-csv";

function ReportContainer({backClick, selectedItem}) {

    const[agentSelectedItem, setAgentSelectedItem] =  React.useState('');
    const[agentItemSelected, setAgentItemSelected] =  React.useState(false);
    const[downloadData, setDownloadData] =  React.useState([]);
    const[headers, setHeaders] =  React.useState([]);
    const[displayLoader, setDisplayLoad] =  React.useState(false);
    const[agents, setAgents] =  React.useState([]);
    const[selectedAgent, setSelectedAgent] =  React.useState(null);
    const[fileName, setFileName] =  React.useState('c1csv');

    var options = {
        '1a' : "allAgentInfo",
        '1b' : "specificAgentInfo",
        '2a' : "allAgentUserInfo",
        '2b' : "specificAgentUserInfo",
        '3a' : "allAgentRevenue",
        '3b' : "specificAgentRevenue",
    }

    React.useEffect( () => {
        fetchAllMainAgents();
      }, [])

    const onItemClick = async(item) => {
        console.log("onItemClick: ", item, options[item])
        setAgentSelectedItem(options[item])
        setAgentItemSelected(true)
        setSelectedAgent(null)
        setDisplayLoad(null)
        setDownloadData([])
 
    }

    const returnToMainOptions = async() => {
        setAgentItemSelected(false)
        setSelectedAgent(null)
        setDisplayLoad(null)
        setDownloadData([])
        backClick();
    }

    const fetchAllAgentInfo = async() => {
        setDisplayLoad(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-all-agents`).then((res)=>{
            if(res.data.status === 200)
            {
                console.log(res.data)
                var newHeaders = [
                    { label: "Userid", key: "userid" },
                    { label: "Info", key: "name" },
                    { label: "Role", key: "u_role" },
                    { label: "Main Agent", key: "agentParentName" },
                    { label: "Created", key: "createdAt" },

                  ];

                  setHeaders(newHeaders)
                  setDownloadData(res.data.response)
                  setDisplayLoad(false)
            }
          })
    }

    const fetchAllMainAgents = async() => {
        var adminObj = {
            role: 'admin'
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-all-admins`, adminObj).then((res)=>{
            if(res.data.status === 200)
            {
                setAgents(res.data.response)
                
            }
          })
    }

    

    const fetchAllSelectedAgentInfo = async() => {
            setDisplayLoad(true)
            console.log("userid: ", selectedAgent)
            var subAgentObj = {
                userid: selectedAgent
            }
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-selected-agents?userid=${selectedAgent}`).then((res)=>{
                if(res.data.status === 200)
                {
              
                    var newHeaders = [
                        { label: "Userid", key: "userid" },
                        { label: "Info", key: "name" },
                        { label: "Role", key: "u_role" },
                        { label: "Main Agent", key: "agentParentName" },
                        { label: "Created", key: "createdAt" },
    
                      ];
    
                      setHeaders(newHeaders)
                      setDownloadData(res.data.response)
                      setDisplayLoad(false)
                }
              })
    }

    
    const fetchAllSelectedAgentUserInfo = async() => {
        setDisplayLoad(true)
        console.log("userid: ", selectedAgent)
        var subAgentObj = {
            userid: selectedAgent
        }
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-selected-agent-user-info?userid=${selectedAgent}`).then((res)=>{
            if(res.data.status === 200)
            {
                console.log(res.data)
                var newHeaders = [
                    { label: "Userid", key: "userid" },
                    { label: "Name", key: "name" },
                    { label: "Surname", key: "lastname" },
                    { label: "Email", key: "email" },
                    { label: "Main Agent", key: "agentParentName" },
                    { label: "Signed Up", key: "createdAt" },

                  ];

                  setHeaders(newHeaders)
                  setDownloadData(res.data.response)
                  setDisplayLoad(false)
            }
          })
    }


        const fetchAllSelectedAgentRevenueInfo = async() => {
            setDisplayLoad(true)
            console.log("userid: ", selectedAgent)

            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-selected-agent-user-revenue-info?userid=${selectedAgent}`).then((res)=>{
                if(res.data.status === 200)
                {
                    console.log("revenue: ", res.data)
                    var newHeaders = [
                        { label: "Userid", key: "userid" },
                        { label: "Name", key: "agent" },
                        { label: "Total Received USDT", key: "totalReceivedInRevenueUSDT" },
                        { label: "Total Received BTC", key: "totalReceivedInRevenueBTC" },
                        { label: "Onboarding Received USDT", key: "totalOnboardfeeUSDT" },
                        { label: "Onboarding Received ZAR", key: "totalOnboardfeeZAR" },
                        { label: "Onboarding Received BTC", key: "totalOnboardfeeBTC" },
                        { label: "Total Company USDT", key: "totalDepositUSDT" },
                        { label: "Total Company ZAR", key: "totalDepositZAR" },
                        { label: "Total Company BTC", key: "totalDepositBTC" },
                        { label: "Last Deopsit Date", key: "lastDepositDate" },
                        { label: "Second Deopsit Date", key: "secondLastDepositDate" },
                    ];

                    setHeaders(newHeaders)
                    setDownloadData(res.data.response)
                    setDisplayLoad(false)
                }
            })
        }

        const changeFileName = async(name) => {
            setFileName(name)
        }

    // var headers = [
    //     { label: "Userid", key: "userid" },
    //     { label: "From User", key: "from_userid" },
    //     { label: "Amount", key: "amount" },
    //     { label: "Percentage", key: "percentage" },
    //     { label: "Info", key: "userinfo" },
    //     { label: "Date", key: "createdAt" },

    //   ];

    //   setHeaders(headers)
  return (
    <div>
        <RedirectBack backClick={returnToMainOptions} />

        {/* Items to select from */}
        <Row className="mt-3">
            {/* See what item did the admin select */}
            {selectedItem === 1 ?
            // They selected one so this is where we will allow them to either select a specific admin or all admin and their agents
            <Row>
            
                <ReportSelection title="All Agents" body="Select all agents and sub agents. This will download all the users" productValue={'1a'} onItemClick={onItemClick} />

                <ReportSelection title="Specific Agent" body="Select a specific agent to get data and sub agent data" productValue={'1b'} onItemClick={onItemClick} />

            </Row>
            : selectedItem === 2 ?
            // They selected one so this is where we will allow them to either select a specific admin or all admin and their agents
            <Row>

                <ReportSelection title="All Agent Users" body="Display all agent and sub agent users" productValue={'2a'} onItemClick={onItemClick} />

            </Row>
            : selectedItem === 3 ?
            // They selected one so this is where we will allow them to either select a specific admin or all admin and their agents
            <Row>
            
                {/* <ReportSelection title="All Agents" body="Show all agents revenue info" productValue={'3a'} onItemClick={onItemClick} /> */}

                <ReportSelection title="Specific Agent" body="Select a specific agent to get revenue info" productValue={'3b'} onItemClick={onItemClick} />

            </Row>
            : ''}
        </Row>

        {/* Selected Items */}
        {agentItemSelected?
        <Row className="mt-3">
            {/* See what item did the admin select */}
            {selectedItem === 1 ?
            // They selected one so this is where we will allow them to either select a specific admin or all admin and their agents
            displayLoader ?
                <p className="text-dark">Loading...</p>
                :
                <div>
                    
                    {agentSelectedItem === 'allAgentInfo' ?
                        
                        downloadData && downloadData.length > 0 ?
                        <div>
                        <Label>Enter CSV File Name</Label>
                        <input type="text" className="form-control form-input mb-2" onChange={(e) => {changeFileName(e.target.value)}} />
                       <CSVLink
                       className="btn btn-primary rounded border-2-primary w-100"
                       data={downloadData}
                       headers={headers}
                       filename={fileName}
                       >
                       Download CSV
                       </CSVLink>
                       </div>
                            : 
                        <Button className="btn btn-warning" onClick={() => fetchAllAgentInfo()}>Fetch Data</Button>
                    
                    : agentSelectedItem === 'specificAgentInfo' ?
                        displayLoader ?
                        <p className="text-dark">Loading...</p>
                        :
                        <div>              
                        <InputGroup className="mb-3">
                            <Label className="input-group-text">Select Agent</Label>
                            <select
                            className="form-select"
                            onClick={(e) => {
                                setSelectedAgent(e.target.value)
                                setDownloadData([])
                            }}
                            >
                            <option value="" >
                                select
                            </option>
                            {(typeof agents !== "undefined" && agents.length > 0)? 
                                agents.map((agent, key) => (
                                <option key={key} value={agent.userid}>{agent.name}</option>
                            ))
                            : ''}
                            
                            </select>      
                        </InputGroup>
                        {selectedAgent ?
                            <Row>
                                {downloadData && downloadData.length > 0 ?
                                <div>
                                <Label>Enter CSV File Name</Label>
                        <input type="text" className="form-control form-input mb-2" onChange={(e) => {changeFileName(e.target.value)}} />
                               <CSVLink
                               className="btn btn-primary rounded border-2-primary w-100"
                               data={downloadData}
                               headers={headers}
                               filename={fileName}
                               >
                               Download CSV
                               </CSVLink>
                               </div>
                                : 
                                <Button className="btn btn-warning" onClick={() => fetchAllSelectedAgentInfo()}>Fetch Data</Button>
                                }
                            </Row>
                            : 
                            ''}
                        </div>
                    
                    :''
                    }

                </div>
            : selectedItem === 2 ?
             agentSelectedItem === 'allAgentUserInfo' ?
            displayLoader ?
            <p className="text-dark">Loading...</p>
            :
            <div>              
                <InputGroup className="mb-3">
                    <Label className="input-group-text">Select Agent</Label>
                    <select
                    className="form-select"
                    onClick={(e) => {
                        setSelectedAgent(e.target.value)
                        setDownloadData([])
                    }}
                    >
                    <option value="" >
                        select
                    </option>
                    {(typeof agents !== "undefined" && agents.length > 0)? 
                        agents.map((agent, key) => (
                        <option key={key} value={agent.userid}>{agent.name}</option>
                    ))
                    : ''}
                    
                    </select>      
                </InputGroup>
                {selectedAgent ?
                    <Row>
                        {downloadData && downloadData.length > 0 ?
                        <div>
                        <Label>Enter CSV File Name</Label>
                        <input type="text" className="form-control form-input mb-2" onChange={(e) => {changeFileName(e.target.value)}} />
                       <CSVLink
                       className="btn btn-primary rounded border-2-primary w-100"
                       data={downloadData}
                       headers={headers}
                       filename={fileName}
                       >
                       Download CSV
                       </CSVLink>
                       </div>
                        : 
                        <Button className="btn btn-warning" onClick={() => fetchAllSelectedAgentUserInfo()}>Fetch Data</Button>
                        }
                    </Row>
                    : 
                    ''}
            </div> 
            : ''
            : selectedItem === 3 ?
            agentSelectedItem === 'specificAgentRevenue' ?
           displayLoader ?
           <p className="text-dark">Loading...</p>
           :
           <div>              
               <InputGroup className="mb-3">
                   <Label className="input-group-text">Select Agent</Label>
                   <select
                   className="form-select"
                   onClick={(e) => {
                       setSelectedAgent(e.target.value)
                       setDownloadData([])
                   }}
                   >
                   <option value="" >
                       select
                   </option>
                   {(typeof agents !== "undefined" && agents.length > 0)? 
                       agents.map((agent, key) => (
                       <option key={key} value={agent.userid}>{agent.name}</option>
                   ))
                   : ''}
                   
                   </select>      
               </InputGroup>
               {selectedAgent ?
                   <Row>
                       {downloadData && downloadData.length > 0 ?
                       <div>
                        <Label>Enter CSV File Name</Label>
                        <input type="text" className="form-control form-input mb-2" onChange={(e) => {changeFileName(e.target.value)}} />
                       <CSVLink
                       className="btn btn-primary rounded border-2-primary w-100"
                       data={downloadData}
                       headers={headers}
                       filename={fileName}
                       >
                       Download CSV
                       </CSVLink>
                       </div>
                       : 
                       <Button className="btn btn-warning" onClick={() => fetchAllSelectedAgentRevenueInfo()}>Fetch Data</Button>
                       }
                   </Row>
                   : 
                   ''}
           </div> 
           : ''
           : '' }
        </Row>
        : ''}
    </div>
  )
}

export default ReportContainer