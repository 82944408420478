import React, { Component, useState, useEffect  } from "react"
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Table,
  Button,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
} from "reactstrap"
import classnames from "classnames"
  import { UserContext } from "../../../UserContext";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactApexChart from "react-apexcharts"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import PendingPaymentTable from "../../Dashboard/PendingPaymentTable"


// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
export const PendingInterest =()=>{

  const [userName, setuserName] = React.useState('');
  const [userEmail, setuserEmail] = React.useState('');
  const [userUserid, setuserUserid] = React.useState('');
  const [pendingPayments, setPendingPayments] = React.useState([]);
  const [allDates, setAllDates] = React.useState([]);
  const [showPendingPayments, setShowPendingPayments] = React.useState(false);

  const [currentDateId, setCurrentDateId] = React.useState('');
  const [vipOption, setVipOption] = React.useState('false');

  
  const [forUser, setForUser] = React.useState('');
  
  
  const [currentPercentage, setCurrentPercentage] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState(false);
  const [getProductid, setCurrentProductId] = React.useState('');
  

const{auth,user} = React.useContext(UserContext)
const[StartpackList,setStartpackList] = React.useState([])


React.useEffect( () => {
    
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
          // 
          setuserName(user.user.name);
          setuserEmail(user.user.email);
          setuserUserid(user.user.userid);

          getAllDates(user.user.userid)
      }
    }
  }, [auth])

const [isMenu, setisMenu] = useState(false)
const [activeTab, setActiveTab] = useState("1")

const toggleTab = tab => {
  if (activeTab !== tab) {
    setActiveTab(tab)
  }
}

const toggleMenu = () => {
  setisMenu(!isMenu)
}



const getAllProducts = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/all-products").then(async res =>{

    console.log('set all products ', res.data.Products)
    return setAllProducts(res.data.Products);
 
  })

}

const getAllDates = async userid => {
 
  await axios.get(process.env.REACT_APP_BASE + "api/payment-dates").then(async res =>{
    // allDates
    return setAllDates(res.data.dates);
 
  })

}

const findProductInterest = async () => {

    // var productid = req.body.productid;
    // var adminuserid = req.body.userid;
    // var percentage = req.body.percentage;
    // var dateid = req.body.dateid;
    // var fromadmin = req.body.fromadmin;

  const InterestObject = {
    dateid: currentDateId,
  }

  console.log(getProductid)

  await axios.post(process.env.REACT_APP_BASE + "api/find-pending-paments",InterestObject).then(async res =>{

    console.log(res.data)
    
    if(res.data.status === 200)
    {
        setShowPendingPayments(true)
        setPendingPayments(res.data.pendingPayments)
        toast.success('Loading Data', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }else{
        toast.error('Unable to find Payments', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }

  });
}


const payInterest = async() => {

    var InterestObject = {
        dateid: currentDateId,
        referralUserid: 'C1A0000002'
    }

    console.log(InterestObject)
    await axios.post(process.env.REACT_APP_BASE + "api/send-productinterests",InterestObject).then(async res =>{

        console.log(res.data)
        
        if(res.data.status === 200)
        {
            toast.success('Payment Loaded', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                // setTimeout(() => {window.location.href="/dashboard"; }, 3000);
        }else{
            toast.error('Unable To Load Payment Right Now', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }

    })
}

    return(
      <React.Fragment>
      <div className="page-content">
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Crypto" breadcrumbItem="Add Product Interest" />

          <Row>

          <Col xl="12">
              <Card>
                <CardBody>
                <h4 className="card-title mb-4">Product Interest</h4>

                <div className="mt-4 mb-3">

                  <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" id="buy-tab">


                      <div>
                        <div>
                        <Label>Dates:</Label>
                        <InputGroup className="mb-3">
                            <select className="form-control" name="allproducts" id="allproducts" onChange={(e)=>setCurrentDateId(e.target.value)} value={currentDateId}>
                                <option className="form-control"  value="">Select Payment Date</option>
                                {allDates.map((date, key) => (
                                    <option className="form-control" key={date.id} value={date.id}>{date.payment_date}</option>
                                ))}

                            </select>
                        </InputGroup>

                        </div>

                        <div className="text-center">
                          <Button
                            onClick={(e)=>findProductInterest()}
                            type="button"
                            color="success"
                            className="w-md bg-success"
                          >
                            Find Pending Interest
                          </Button>
                        </div>
                      </div>
                    </TabPane>

                    {showPendingPayments ?
                    <div>
                        <button className="btn btn-primary" onClick={(e)=>payInterest()} >Pay Now</button>
                 
                        <PendingPaymentTable users={pendingPayments} />
                    </div>
                    : ''}
                   
                  </TabContent>
                </div>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
    )
}