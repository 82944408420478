import React, { useEffect, useRef } from "react";

import axios from 'axios'
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Spinner
} from "reactstrap";

function ReportSelection({title, body, productValue, onItemClick}) {
  return (
    <Col lg={4} md={4}>
              <a onClick={() => onItemClick(productValue)} >
                  <Card outline color="primary" className="border c1-expand-hover h-100">
                      <CardBody>
                      <CardTitle className="mt-0 text-primary">{title}</CardTitle>
                      <CardText className="text-primary">
                      {body}
                      </CardText>
                      </CardBody>
                  </Card>
              </a>
            </Col>
  )
}

export default ReportSelection