import React, { useEffect, useRef } from "react";


import { UserContext } from "../../UserContext";
import axios from 'axios'


import FilterTableUsers from '../Dashboard/FilterTableUsers'
function AllUsers() {

    const{auth,user} = React.useContext(UserContext)
    const[users, setUsers] =  React.useState([]);
  
    React.useEffect( () => {
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        }else{
          getUsers(user.user, user.user.userid)
        }
      }
    }, [auth])

    const getUsers = async(info, userid) => {
        const userData = {
            info: info,
            userid: userid
          }
      
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-users`,userData).then((res)=>{
      
            if(res.data.status == 200)
            {
                console.log("Users to display is: ", res.data.users)
                setUsers(res.data.users)
                console.log(res.data.users[0])
              
            }
      
          })
    }

  return (
    <div>
        <FilterTableUsers users={users} />
    </div>
  )
}

export default AllUsers