import React, { useEffect, useRef } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Spinner
} from "reactstrap";

function RedirectBack({backClick}) {
  return (
    <div>
        <Button className='btn btn-danger' onClick={() => backClick()}>Back</Button>
    </div>
  )
}

export default RedirectBack