import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";


import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../LatestTranactionCol";

import TableContainer from "../../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
function MyAgentTable({users}) {

    console.log(users)
  
  const columns = useMemo(
      () => [
        {
          Header: "Userid",
          accessor: "userid",
          filterable: true,
          disableFilters: true,
          Cell: cellProps => {
            return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Username",
          accessor: "name",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
              return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Email",
          accessor: "email",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
              return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Role",
          accessor: "u_role",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
              return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Onboarding Fee",
          accessor: "onboardingFee",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <BillingName {...cellProps} />;
            },
        },
      ],
      []
    );
  
    const editUserBtn = async(id) => {
      console.log("Clicked Button " + id)
      window.location.href="/edit-admin/" + id
    }
    return (
      <React.Fragment>
    
        <Card>
          <CardBody>
            <div className="mb-4 h4 card-title">User Info</div>
            <TableContainer
              columns={columns}
              data={users}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={20}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  };
  
export default MyAgentTable