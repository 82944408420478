import React, { useEffect, useState } from "react";

// import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import PropTypes from 'prop-types'

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"
function ReportSelection({optionClicked, role = 'viewer'}) {
    
  return (
    <Row>
        <Row>
            <Col lg={12} md={12}>

                <Row style={{padding: "30px 0"}}>
                    <Col lg={4} md={4}>
                        <a value="transactions" onClick={(e) => {optionClicked("transactions")}}>
                            <Card outline color="primary" className="border c1-expand-hover h-100">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-primary">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Transactions
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-primary">Investment Portfolio Transaction Report</CardTitle>
                                <CardText className="text-primary">
                                To view all user transactions, please select the desired month. 
                                <br /><br />
                                Additionally, a CSV file of the report will be sent to your email for your records.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

                    <Col lg={4} md={4}>
                        <a value="productSales" onClick={(e) => {optionClicked("productSales")}}>
                            <Card outline color="success" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-success">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Product Sales
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-success">Portfolio Performance Report</CardTitle>
                                <CardText className="text-success">
                                Retrieve the performance report of all investments in the portfolio for a certain month. A quick summary of the portfolio performance will also be displayed, including key performance indicators such as return on investment (ROI) and risk measures.
                                <br /><br />
                                A CSV file containing the detailed investment transactions will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

                    <Col lg={4} md={4}>
                        <a value="proceeds" onClick={(e) => {optionClicked("proceeds")}}>
                            <Card outline color="dark" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-dark">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Proceeds
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-dark">Investment Returns Report</CardTitle>
                                <CardText className="text-dark">
                                Retrieve a report on all returns generated by the client's portfolio, including capital gains, dividends, and interest. The report will also display a list of commissions received on the investments.
                                <br /><br />
                                A CSV file containing the detailed transaction information will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

                </Row>
            </Col>
        </Row>

        <Row>
            <Col lg={12} md={12}>

                <Row style={{padding: "30px 0"}}>

                {role === 'admin' || role ==='super' ?
            
                    <Col lg={4} md={4}>
                        <a value="agentComms" onClick={(e) => {optionClicked("agentComms")}}>
                            <Card outline color="warning" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-warning">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Agent Comms
                                </h5> 
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-warning">Agent Commission Report</CardTitle>
                                <CardText className="text-warning">
                                This report will display the commissions earned by agents on certain investment plans. The proceeds generated by these plans will <strong>NOT</strong> be included in this report.
                                <br /><br />A CSV file containing the detailed commission information will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>
                : ''}

                    <Col lg={4} md={4}>
                        <a value="productSales" onClick={(e) => {optionClicked("exchanges")}}>
                            <Card outline color="danger" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-danger">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Exchanges
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-danger">Exchanges Performance Report</CardTitle>
                                <CardText className="text-danger">
                                Retrieve the performance report of all exchanges for a certain month. A quick summary of the exchanges will also be displayed.
                                <br /><br />
                                A CSV file containing the detailed exchanges will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

        
                    {/* <Col lg={4} md={4}>
                        <a value="productExpire" onClick={(e) => {optionClicked("p_expire")}}>
                            <Card outline color="primary" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-primary">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Product Final Term
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-primary">Ending Contracts Report</CardTitle>
                                <CardText className="text-primary">
                                The expiration report will exhibit the present term of the investments while also highlighting nearing completion of an investment term.
                                <br /><br />
                                A CSV file containing the detailed exchanges will be available for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col> */}
                    
                </Row>
            </Col>
        </Row>

        <Row>
            <Col lg={12} md={12}>

                <Row style={{padding: "30px 0"}}>
                    <Col lg={4} md={4}>
                        <a value="deposits" onClick={(e) => {optionClicked("deposits")}}>
                            <Card outline color="danger" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-danger">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Deposits
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-danger">Investment Deposits Report</CardTitle>
                                <CardText className="text-danger">
                                The deposits report will contain a list of all deposits made by the client, including bank transfers, altcointrader transactions, and other deposit methods. This report will not include any information on proceeds generated by the investments.

                                <br /> <br />
                                A CSV file containing the detailed transaction information will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

                    <Col lg={4} md={4}>
                        <a value="withdrawals" onClick={(e) => {optionClicked("withdrawals")}}>
                            <Card outline color="info" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-info">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Withdrawals
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-info">Investment Withdrawals Report</CardTitle>
                                <CardText className="text-info">
                                The withdrawals report will contain a list of all withdrawals made by the client, including both pending and completed withdrawals. This report will provide an overview of the client's cash flow from the portfolio.
                                <br /><br />
                                A CSV file containing the detailed transaction information will be sent to your email for further analysis.
                                
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col>

                    {/* <Col lg={4} md={4}>
                        <a value="userInfo" onClick={(e) => {optionClicked("users")}}>
                            <Card outline color="dark" className="border c1-expand-hover h-100 hover-overlay hover-zoom hover-shadow ripple">
                                <CardHeader className="bg-transparent">
                                <h5 className="my-0 text-dark">
                                    <i className="mdi mdi-bullseye-arrow me-3" />Users
                                </h5>
                                </CardHeader>
                                <CardBody>
                                <CardTitle className="mt-0 text-dark">Investor Information Report</CardTitle>
                                <CardText className="text-dark">
                                The investor information report will display all information related to the client's account, including their wallet balance, investment holdings, and transaction history. This report will not include information on product proceeds.
                                <br /> <br />
                                A CSV file containing the detailed account information will be sent to your email for further analysis.
                                </CardText>
                                </CardBody>
                            </Card>
                        </a>
                    </Col> */}
                    
                </Row>
            </Col>
        </Row>
    </Row>
  )
}
ReportSelection.propTypes = {
    optionClicked: PropTypes.func,
    role: PropTypes.any
  }
  
export default ReportSelection