import React, { useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from "react-dropzone"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Button,
    Input,
    InputGroup,
  } from "reactstrap";

function AdminTradingReport() {
  const { auth } = React.useContext(UserContext);

  const [name, setReportName] = useState("");
  const [date, setDate] = useState("");
  const [companies, setCompanies] = useState([]);
  const [importFile, setImportFile] = useState([]);
  const [company, setCompany] = useState("");
  const [week, setWeek] = useState(0);

  useEffect(() => {
    if (auth != null) {
      if (!auth) {
        window.location.href = "/login";
      } else {
        // Perform any other necessary actions for authenticated users
        getAllCompanies()
      }
    }
  }, [auth]);

  const getAllCompanies = async() => {
    
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-all-companies`,{}).then(async (res)=>{
        if(res.data.status == 200)
        {
            console.log("the company info is: ", res.data.data)
            setCompanies(res.data.data)
        }
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

function handleAcceptedFiles(files) {

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
      console.log(files)
    setImportFile(files)
    
  }

    const handleFileUpload = async () => {

        console.log("file that is used is: ", importFile)
        //company, name, month
    const formData = new FormData();
    formData.append("name",name);
    formData.append("company",company);
    formData.append("month",date);
    formData.append("week",week);
    formData.append("tradingReport",importFile[0])

    if(name === '' || company == '' || date === ''|| week === '' || !importFile || importFile.length <= 0)
    {
        toast.error('Error, please ensure all fields are fille in.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            return false;
    }

    try {
        const response = await axios.post(process.env.REACT_APP_BASE + "api/admin-upload-trading-report", formData,);

        console.log(response.data);
        toast.success('File uploaded successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

        setTimeout(() => {window.location.reload(); }, 2000);
    } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Error uploading file. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    };

  const handleNameChange = async(value) => {
    setReportName(value)
  }

  
  const handleDateChange = async(value) => {
    setDate(value)
  }

  const handleWeekChange = async(value) => {
    setWeek(value)
  }


  return (
    <React.Fragment>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
      <div className="page-content">
        <div className="container-fluid">
            <div>
                <h3>Admin Trading Report</h3>
                <div className="mb-3 mt-2">
                    <label className="form-label">Report Title</label>
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Report Title"
                    value={name}
                    onChange={(e) => {handleNameChange(e.target.value)}}
                    />

                </div>

                <div className="mb-3 mt-2">
                    <label className="form-label">Report Date</label>
                    <input
                    type="month"
                    className="form-control"
                    value={date}
                    onChange={(e) => {handleDateChange(e.target.value)}}
                    />

                </div>

                <div className="mb-3 mt-2">
                    <label className="form-label">Week</label>
                    <select className="form-control" value={week} onChange={(e) => handleWeekChange(e.target.value)}>
                      <option value={0}>Select Week</option>
                      <option value={1}>Week 1</option>
                      <option value={2}>Week 2</option>
                      <option value={3}>Week 3</option>
                      <option value={4}>Week 4</option>
                      <option value={5}>Week 5</option>
                    </select>

                </div>

                <div className="mb-3 mt-2">
                    <label className="form-label">Select Company</label>
                    <select className="form-control" value={company} onChange={(e) => {setCompany(e.target.value)}}>
                        <option value="all">All</option>
                        {companies && companies.map((company, key) => {
                            // Add the return statement here
                            return <option key={key} value={company.id}>{company.name}</option>;
                        })}
                    </select>
                </div>

                <div className="mb-3 mt-2">
                    <Row>
                        <Col xl="12">
                        <Card>
                            <CardBody>
                            <div className="dropzone-previews mt-3" id="file-previews">
                            {importFile && importFile.length > 0 ?
                                <div className="bg-primary rounded-pill" style={{width: "fit-content"}}>
                                    <h5 className="text-white ">{importFile[0].name}</h5>
                                </div>
                                
                                : ''}
                            </div>
                                <Dropzone
                                onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles)
                                }}>
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} type="file" name="userUpload" />
                                        <div className="dz-message needsclick">
                                            <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>Drop Report to upload File.</h4>
                                        </div>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>                                             
                        
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>
                </div>

                <div className="mb-3 mt-2">
                
                    <button className="btn btn-primary" onClick={() => {handleFileUpload()}}>
                        Upload
                    </button>
                </div>

                </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminTradingReport;
