import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function PendingExchange() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Pending Exchanges');

    const [disableBtn, setDisableBtn] = useState(false);

    const[transactions, setExchanges] =  React.useState([]);

    const generate2Decimal = async (number) => {
      const truncatedNumber = Math.floor(number * 100) / 100; 

      return truncatedNumber; 
    }

    

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Amount From",
            accessor: "amount_from",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
          {
            Header: "From Amount",
            accessor: "from_amount",
            disableFilters: true,
            Cell: 
            cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.amount_from === '1' || cellProps.row.original.amount_from === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.amount_from === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
          
          {
            Header: "Amount To",
            accessor: "amount_to",
            disableFilters: true,
            Cell: cellProps => {
              // return <PaymentMethod {...cellProps} />;
                        return (
                <p
    
                >
                  {cellProps.value == '1' ? 'USDT' : cellProps.value == '2' ? 'ZAR' : 'BTC'}
                </p>
              );
            },
          },
            
          {
            Header: "Before Deduction",
            accessor: "exchange_amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.amount_to === '1' || cellProps.row.original.amount_to === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.amount_to === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
          {
            Header: "Fee",
            accessor: "fee",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.amount_to === '1' || cellProps.row.original.amount_to === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.amount_to === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
          {
            Header: "Amount To Wallet",
            accessor: "amount",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              let formattedAmount = parseFloat(cellProps.value);
          
              if (cellProps.row.original.amount_to === '1' || cellProps.row.original.amount_to === '2') {
                formattedAmount = formattedAmount.toFixed(2);
              } else if (cellProps.row.original.amount_to === '3') {
                formattedAmount = formattedAmount.toFixed(6);
              }
          
              return <span>{formattedAmount}</span>;
            },
          },
     
     
      
          {
            Header: "Action",
            accessor: "id",
            disableFilters: true,
            Cell: cellProps => {
                return (
                    <div>
                    <button className="btn btn-rounded btn-success" id="approveButton" disabled={disableBtn} onClick={(e) => { approveDeposit(cellProps.value) }}>
                      Approve
                    </button>
                    <button className="btn btn-rounded btn-danger" id="declineButton" disabled={disableBtn} onClick={(e) => { declineDeposit(cellProps.value) }}>
                      Decline
                    </button>

                    </div>
                  );
            },
          },
    
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits()
    
          }
        }
      }, [auth])
    
      const approveDeposit = async (id) => {
        document.getElementById("approveButton").disabled = true; // Disable the button immediately
        var depositObj = {
          exchangeid: id,
        };

      
        try {
          await axios.post(process.env.REACT_APP_BASE + "api/handle-new-exchange", depositObj).then(async (res)=>{
              
            if(res.data.status === 200)
            {
                await getUserDeposits()
           
            }
            // window.location.reload();    
        });

          await getUserDeposits();
        } catch (error) {
          // Handle errors if needed
        } finally {
          document.getElementById("approveButton").disabled = false; // Re-enable the button
        }
      };

      const declineDeposit = async (id) => {
        document.getElementById("declineButton").disabled = true; 
        var depositObj = {
          exchangeid: id,
        }
        try {
          await axios.post(process.env.REACT_APP_BASE + "api/decline-new-exchange", depositObj).then(async (res)=>{
              
              if(res.data.status === 200)
              {
                  await getUserDeposits()
             
              }
              // window.location.reload();    
          });
          } catch (error) {
            // Handle errors if needed
          } finally {
            document.getElementById("declineButton").disabled = false; // Re-enable the button
          }
      }

      const getUserDeposits = async () => {
        
        await axios.get(process.env.REACT_APP_BASE + "api/all-exchanges").then(async (res)=>{
            console.log("Exchanges received is ", res.data.exchanges)
          setExchanges(res.data.exchanges)

          
        });
      }


  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
  <Col md="12" sm="12">
        <UserSingleTable tableData={transactions} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingExchange




