import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

class PerformanceGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'bar', // Change type to 'bar' for a column chart
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth', // You can choose a curve type if needed
        },
        title: {
          text: 'Total Proceeds paid by Month',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#009EDF', 'transparent'],
            opacity: 0.2,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/monthly-graph-performance-data`);
        console.log(response.data)
        if (response.data.status === 200) {
           
          const rawData = response.data.data;
    
          // Initialize an object to store the processed data
          const processedData = {};
    
          // Loop through the raw data and process it
          rawData.forEach((entry) => {
            const date = entry.date;
            const values = { "USDT": "0" }; // Default values for "USDT," "ZAR," and "BTC"
    
            // Copy the actual values if present
            if (entry["1"] !== undefined) values["USDT"] = entry["1"];
    
            // Add the processed entry to the result
            processedData[date] = values;
          });
    
          // Create series for the chart
          const series = ["USDT"].map((currency) => ({
            name: currency,
            data: Object.values(processedData).map((entry) => entry[currency]),
          }));
    
          // Create options for the chart
          const options = {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: Object.keys(processedData),
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                },
              },
            },
          };
    
          this.setState({ series, options });
        } else {
          console.error('Failed to fetch monthly deposit data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching monthly deposit data:', error);
      }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    );
  }
}

export default PerformanceGraph;
