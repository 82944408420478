import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Label,
    Button
  } from "reactstrap";

function UserNotifications() {

    const{auth,user} = React.useContext(UserContext)
   
    const[userid, setUserid] =  React.useState('');
    const[adminInfo, setAdminInfo] =  React.useState('');
    const [allUsers, setAllUsers] = useState([])
    
    const[notificationDescription, setNotificationDescription] =  React.useState('');
    const[notificationTitle, setNotificationTitle] =  React.useState('');
    
    const [taskName, setTaskName] = useState('')
    const [taskDescription, setTaskDescription] = useState('')
    const [assigendAdmin, setAssigendAdmin] = useState('')
    const [newUserid, setNewUserid] = useState('')
    
    const [adminUsers, setAdminUsers] = useState([])
    const [allTasks, setAllTasks] = useState([])

    const [adminUserArr, setAdminUserArr] = useState([])

    React.useEffect( () => {
  
        if (auth != null) {
            if (!auth) {
              window.location.href="/login"
            }else{
             if(user)
             {
              setNewUserid(user.user.userid)
              setAdminInfo(user.user)
              getAllAdminUsers(user.user)
             }
      
            }
          }
      }, [auth])


    const confirmSendNotification = async (userid) => {
        confirmAlert({
            title: 'Confirm to Send Notification',
            message: 'Are you sure to send notification?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => sendNotification(userid)
              },
              {
                label: 'No',
                //onClick: () => alert('Click No')
              }
            ]
          });
        
      
    }

    const sendNotification = async (userid) => {
        var notificationObj = {
            userid: adminUserArr,
            title: notificationTitle,
            message: notificationDescription,
            info: adminInfo,
            user: newUserid
        }

        console.log(notificationDescription)
        
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-multiple-notification`,notificationObj).then((res)=>{

            if(res.data.status == 200)
            {
                toast.success('Notification Sent', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    setNotificationDescription('')
                    setNotificationTitle('')
             
            }else{
                toast.error('Unable to send Notification!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
      
          })
    }

    const changeNotificationDescription = async (description) => {
        console.log(description)
        setNotificationDescription(description)
    }

    const changeNotificationTitle = async (title) => {
        setNotificationTitle(title)
    }

    const getAllAdminUsers = async(info) => {
        var notificationObj = {
            info: info
        }
        
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-admin-users`,notificationObj).then((res)=>{
            console.log("we are getting the following users ", res.data)
            if(res.data.status == 200)
            {
                setAllUsers(res.data.users)
            }
      
          })
    }

    const addAdminUser = async () => {
    

         if(assigendAdmin.toString()  === 'all')
         {
            console.log("at the moment it is ")
         
            // setAdminUserArr([...adminUserArr, assigendAdmin])
            setAdminUserArr(['all'])
         }else{

            var array = [...adminUserArr]; // make a separate copy of the array
            var index = array.indexOf('all')
            if (index !== -1) {
              array.splice(index, 1);
              array.push(assigendAdmin.toString())
              setAdminUserArr(array);
            }else{
                setAdminUserArr([...adminUserArr, assigendAdmin.toString()])
            }

            // setAdminUserArr([...adminUserArr, assigendAdmin.toString()])
        }
        
     
    }
  
    const removeUser = async(user) => {
        console.log("user is ", user)
        var array = [...adminUserArr]; // make a separate copy of the array
        var index = array.indexOf(user.toString())
        if (index !== -1) {
          array.splice(index, 1);
          setAdminUserArr(array);
        }
    }

    const changeTeamMember = async (teamMember) => {
        setAssigendAdmin(teamMember)
    }

    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Profile" breadcrumbItem="Profile" />

                    <Row>
                        <Col md="12" sm="12">

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Send Notification:</CardTitle>

                                    <div className="table-responsive">

                                        <label>Title:</label>
                                        
                                        <br />
                                    
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={notificationTitle} 
                                            onChange={(e) => {changeNotificationTitle(e.target.value)}}
                                        />

                                        <br />

                                        <label>Description:</label>
                                        
                                        <br />

                                        <textarea
                                        className="form-control"
                                        value={notificationDescription} 
                                        onChange={(e) => {changeNotificationDescription(e.target.value)}}
                                        ></textarea>

                                        <br />

                                        <div className="inner-repeater mb-4">
                                            <div className="d-inline-block">
                                                {typeof adminUserArr !== 'undefined' && adminUserArr.length > 0 ?
                                                    adminUserArr.map((user, key) => (
                                                        <div style={{borderRadius: "15px", padding: "7px", marginBottom: "5px", marginLeft: "5px", width: "fit-content"}} key={key} className="d-inline-block bg-success text-white">{user} 
                                                        <a className="ml-3 text-danger " onClick={(e) => {removeUser(user)}}> <strong>X</strong> </a> </div>
                                                    ))
                                                : ''}
                                            </div>

                                            <div className="inner form-group mb-0 row">
        
                                                <Label className="col-form-label col-lg-2">
                                                    Assign To User
                                                </Label>
                                                <div className="inner col-lg-10 ml-md-auto" id="repeater">
                                                    <select className="form-control" value={assigendAdmin}  onChange={(e) => {changeTeamMember(e.target.value)}} >
                                                        <option value='' >Please Select User</option>
                                                        <option value='all' >All Users</option>
                                                        {typeof allUsers !== undefined && allUsers.length > 0 ?
                                                        
                                                            Object.keys(allUsers).map((id, key) => (
                                                                <option key={key} value={allUsers[id].userid} >{allUsers[id].name + ' ' + allUsers[id].lastname}</option>
                                                            ))
                                                        : ''}
                                                    </select>
                                                </div>

                                                {assigendAdmin !== '' ? <button type="button" style={{width:"fit-content"}} className="btn btn-success ml-2" onClick={addAdminUser} >Add</button> : ''}
                                            </div>

                                        </div>
                                        
                                        <Button onClick={(e) => {confirmSendNotification(e)}} >Submit</Button>

                                    </div>
                                    
                                </CardBody>
                            </Card>

                        </Col>


                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UserNotifications