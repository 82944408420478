import React, { useEffect, useState, useMemo } from "react";

import { UserContext } from "../../../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import PropTypes from 'prop-types'

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "../../../Dashboard/UserSingleTable";
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../Dashboard/LatestTranactionCol";

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"


function TransactionsReportContainer({displayAllSelection, companyID=''}) {

  const socket = io('https://node-server-alphax-green-0fc80932987b.herokuapp.com', {});
  window.socket = socket;

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    const[dateOptions, setDateOptions] =  React.useState([]);
    const[showLoadingSpinner, setShowLoadingSpinner] =  React.useState(true);

    
    const[foundData, setFoundData] =  React.useState(false);
    const[loadData, setLoadData] =  React.useState([]);

    
    const[dropdownDate, setDropdownDate] =  React.useState('');
    const[reportHeader, setReportHeader] =  React.useState('');
    const[userEmail, setUserEmail] =  React.useState('');
    
    

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            console.log("the company to find is ", companyID)
            getTransactionDates(companyID)
            setUserid(user.user.userid);
            setUserEmail(user.user.email);
           }
    
          }
        }
      }, [auth])

    const getTransactionDates = async(companyID) => {
      var obj = {
        companyID: companyID
      }
        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-transaction-dates", obj).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
                await onTransactionDateChanged('all')
                setDateOptions(res2.data.dates)
                setShowLoadingSpinner(false)
            }
        })
      }

    const onTransactionDateChanged = async(date) => {
       
        if(date =='all')
        {
          setLoadData([])
          setFoundData(false)
          setDropdownDate(date)
          return false;
        } 

        await getTransactionsForDate(date)
        console.log(date)
        setDropdownDate(date)
        setReportHeader(date + "-transactions")
    }

    const getTransactionsForDate = async(date) => {
        setShowLoadingSpinner(true)
        console.log("date is " + date)
        var transactionObj = {
            dates: date,
            companyID: companyID
          }

        var getdata = await axios.post(process.env.REACT_APP_BASE + "api/get-date-transactions", transactionObj).then(async res2 =>{
            console.log(res2.data)
            if(res2.data.status == 200)
            {
              setLoadData(res2.data.transactions)
              setFoundData(true)
                setShowLoadingSpinner(false)

                return true;
            }else{
              setLoadData([])
              setFoundData(false)
                setShowLoadingSpinner(false)
                return false;
            }
        })

        setShowLoadingSpinner(false)
    }

    const columns = useMemo(
      () => [
        {
          Header: "Userid",
          accessor: "userid",
          filterable: true,
          disableFilters: true,
          Cell: cellProps => {
            return <OrderId {...cellProps} />;
          },
        },
        {
          Header: "User",
          accessor: "names",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <BillingName {...cellProps} />;
          },
        },
        {
          Header: "Transaction Type",
          accessor: "tran_type",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Date {...cellProps} />;
          },
        },
        {
          Header: "Transaction ID",
          accessor: "trans_id",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Amount",
          accessor: "amount",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return cellProps.value === '1' ? <p>Approved</p> : cellProps.value === '2' ? <p>Declined</p> : <p>Pending</p>;
          },
        },
        {
          Header: "Date",
          accessor: "createdAt",
          disableFilters: true,
          filterable: true,
          Cell: cellProps => {
            return <Total {...cellProps} />;
          },
        },
      ],
      []
    );

    const DownloadCSVClick = async() => {
      console.log("We need to get download")
      // await getTransactionReport()
      // const fileName = 'my_data.csv';
    
      // return (
      //   <CSVLink data={data} filename={fileName}>
      //     Download CSV
      //   </CSVLink>
      // );
    }

    const getTransactionReport = async() => {
      socket.off('get transactions', getTransactionsNew)

      toast.success('Generating Report, an Email will be sent!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      getTransactions(userEmail)
    }

    const getTransactions = async(newemail) => {
      socket.on('get transactions', getTransactionsNew(newemail));
      socket.emit('get transactions', {email: newemail});
    }

    const getTransactionsNew = async(email) => {
      return email
    }

  return (
    <div>
      <h3  className="text-center">Investment Portfolio Transaction Report</h3>
        <Row>
            <Col md={2}>
                <Button onClick={(e) => {displayAllSelection()}} className={"border-primary bg-primary text-dark"} >Go Back</Button>

            </Col>
        </Row>
        
        {showLoadingSpinner ?
     
                <Spinner type="grow" className="ms-2" color="secondary" />
                :
        <div >
        <Row className="mt-4">
            <Col md={8}>
                <label className="">Select Transaction Month</label>
                <select onChange={(e) => {onTransactionDateChanged(e.target.value)}} value={dropdownDate} className="form-control" id="referrals">
                    <option value="all">All</option>
                    {typeof dateOptions !== 'undefined' && dateOptions.length > 0 ?
                    dateOptions.map((date, key) => (
                        <option key={key} value={date}>{date}</option>
                    ))
                    : ''}
                </select>
            </Col>

            <Col md={4}>
              {typeof loadData === undefined || loadData.length <= 0?
              ''
                // <Button className="btn btn-rounded btn-danger mt-4" onClick={(e) => {DownloadCSVClick()}}>Download CSV Report</Button>
                :
                <CSVLink
                    className="btn btn-rounded btn-danger mt-4"
                    data={loadData}
                    filename={reportHeader}
                >
                    Download CSV
                </CSVLink>
              }
            </Col>
            
        </Row>

        <Row className="mt-4">
          {foundData ? 
          <div>
            {/* <h3>Total: {totalAmount}</h3> //This can be added to relevant reports */}
            <UserSingleTable tableData={loadData} columns={columns} title={"Transactions"} />
          </div>
            
          : "No Data Available"}
        </Row>
        </div>
        }
    </div>
  )
}
TransactionsReportContainer.propTypes = {
    displayAllSelection: PropTypes.func,
  }
export default TransactionsReportContainer