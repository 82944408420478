import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner,
    InputGroup,
    Label
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"

import ReportSelection from "./container/reportSelection";
import TransactionsReportContainer from "./container/reportContainers/TransactionsReportContainer";
import SalesReportContainer from "./container/reportContainers/SalesReportContainer";
import ProceedsReportContainer from "./container/reportContainers/ProceedsReportContainer";
import AgentCommsContainer from "./container/reportContainers/AgentCommsContainer";
import ExchangesReportContainer from "./container/reportContainers/ExchangesReportContainer";
import DepositCommsContainer from "./container/reportContainers/DepositCommsContainer";
import WithdrawReportContainer from "./container/reportContainers/WithdrawReportContainer";
import ProductExpireReportContainer from "./container/reportContainers/ProductExpireReportContainer";
import DepositGraphContainer from "./container/reportContainers/DepositGraphContainer";
import GraphSelection from "./container/graphSelection";
import PerformanceGraphContainer from "./container/reportContainers/PerformanceGraphContainer";

function AdminGraphs() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[email, setEmail] =  React.useState('');
    const[showLoadingSpinner, setShowLoadingSpinner] =  React.useState(false);
    const[selectedOption, setSelectedOption] =  React.useState("");

    const[role, setRole] =  React.useState('');
    const[companyID, setCompanyID] =  React.useState('');
    const[companies, setCompanies] =  React.useState([]);
    
    
    // Set all displays
    const[displaySelection, SetDisplaySelection] =  React.useState(true);


    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
           if(user)
           {
            setEmail(user.user.email)
            setUserid(user.user.userid);
            setRole(user.user.u_role)
            console.log("Role is " + user.user.u_role)
            if(user.user.u_role === 'super')
            {
              getCompanies()
            }else{
              console.log("company ID ", user.user.company_id)
              setCompanyID(user.user.company_id)
            }
           }
    
          }
        }
      }, [auth])

      const getCompanies = async() => {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-all-companies`).then((res)=>{
          if(res.data.status === 200)
          {
            setCompanies(res.data.data)
          }
        })
      }

      const optionClicked = async(value) =>{

        setShowLoadingSpinner(true)
        console.log("The report value options clicked is ", value)
        // if(value === "deposits")
        // {
            setShowLoadingSpinner(false)
            setSelectedOption(value)
            SetDisplaySelection(false)
        // }else{
        //     SetDisplaySelection(true)
        //     setShowLoadingSpinner(false)
        // }
      }

      const displayAllSelection = async(value) =>{
        console.log("show everything again")
        setShowLoadingSpinner(false)
        SetDisplaySelection(true)
      }

      const companySelected = async(company) => {
        console.log("User selected: " + company)
        setCompanyID(company)
      }

      const goBackToMainSelections = async() => {
        console.log("Go back clicked..")
        SetDisplaySelection(true)
      }
     

  return (
    <div className='page-content'>
      <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
        <Container fluid>

        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Reports" breadcrumbItem="Reports" className="m-auto center" />
        <div>
            {showLoadingSpinner ?
                <Spinner type="grow" className="ms-2" color="secondary" />
                :

                <div>

                    {displaySelection ? 
                      <Row>
                        <Col lg={12} md={12}>
                
                          <Row style={{padding: "30px 0"}}>

                            {/* All Deposits */}
                            <GraphSelection 
                              optionClicked={optionClicked} 
                              role={role} 
                              option="deposits" 
                              MainTitle="Deposits"
                              Cardtitle="Monthly Deposits"
                              Cardtext="View all monthly deposits displayed on a graph. " />

                            {/* All Performance Based */}
                            <GraphSelection 
                              optionClicked={optionClicked} 
                              role={role} 
                              option="performance" 
                              MainTitle="Performance"
                              Cardtitle="Performance Charts"
                              Cardtext="View all financial performances on chart. " />
                          </Row>
                        </Col>
                      </Row>
                    : 

                    <div>
                        {selectedOption === "deposits"  ?
                        <DepositGraphContainer displayAllSelection={displayAllSelection} back={goBackToMainSelections} />
                        : 
                        selectedOption === "performance"  ?
                        <PerformanceGraphContainer displayAllSelection={displayAllSelection} back={goBackToMainSelections} />
                        : 
                        ''}

                    </div>}
                    </div>
            }
            </div>
        </Container>
    </div>
  )
}

export default AdminGraphs