import React, { useEffect, useState, useMemo } from "react";

import { UserContext } from "../../../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

import PropTypes from 'prop-types'

import { CSVLink, CSVDownload } from "react-csv";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "../../../Dashboard/UserSingleTable";
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "../../../Dashboard/LatestTranactionCol";

import {
    Card,
    CardHeader,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Button,
    Spinner
  } from "reactstrap";
import withdrawals from "pages/SinglePages/withdrawals";

import io from "socket.io-client"
import DepositGraph from "pages/Reports/Graphs/DepositGraph";


function DepositGraphContainer({back}) {

  return (
    <div>
      <button className="btn btn-danger mb-3" onClick={() => back()}>Back</button>
      <p>Deposit Graph</p>
      <DepositGraph />
    </div>
  )
}
DepositGraphContainer.propTypes = {
  }
export default DepositGraphContainer