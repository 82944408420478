
import React from "react"
import ReactDOM from "react-dom/client";

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./i18n"
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom"

import { Provider } from "react-redux"
// import 'tw-elements';

import CookieBot from "react-cookiebot"
const domainGroupId = '7df7b637-7f70-47b3-8af6-e9dea4fcdc1a';

import store from "./store"


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
     

      <App />
    </BrowserRouter>
  </Provider>
);
// reportWebVitals();