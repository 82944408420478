import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
function PendingKYC() {
    
    const[tableData, setTableData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Pending KYC');

    const editUserBtn = async(userid) => {
        console.log("Clicked Button " + userid)
        window.location.href="/edit-user/" + userid
      }

    const columns = useMemo(
        () => [
          {
            Header: "Userid",
            accessor: "userid",
            filterable: true,
            disableFilters: true,
            Cell: cellProps => {
              return <OrderId {...cellProps} />;
            },
          },
          {
            Header: "Name",
            accessor: "name",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <BillingName {...cellProps} />;
            },
          },
          {
            Header: "Lastname",
            accessor: "lastname",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Date {...cellProps} />;
            },
          },
          {
            Header: "Cell",
            accessor: "cell",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return <Total {...cellProps} />;
            },
          },
          {

            Header: "View Details",
            disableFilters: true,
            accessor: "id",
            Cell: cellProps => {
              return (
                <Button className="btn btn-round btn-success" onClick={(e) => {editUserBtn(cellProps.value)}} >
                View User
              </Button>
              );
            },
          },
        ],
        []
      );

    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits(userid)
    
          }
        }
      }, [auth])
    
    
      const getUserDeposits = async (userid) => {
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-user-pending-kyc`).then((res)=>{
    
          if(res.data.status == 200)
          {
            setTableData(res.data.users)
            
          }
    
        })
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Profile" breadcrumbItem="Profile" />

<Row>
  <Col md="12" sm="12">
        <UserSingleTable tableData={tableData} columns={columns} title={title} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingKYC

