import React, {useState, useEffect} from "react";


import axios from 'axios'

import PDFTransactions from "./PDF/PDFTransactions";
import { PDFDownloadLink } from '@react-pdf/renderer'

function UserTransactionsReport({user}) {

  const [transactions, setTransactions] = useState([])
  const [usdtProductAmount, setUsdtProductAmount] = useState(0)
  const [btcProductAmount, setBTCProductAmount] = useState(0)
  const[name, setName] =  React.useState('');
  const[userid, setUserid] =  React.useState('');
  const[useDate, setUseDate] =  React.useState('');
  const[totalZAR, setTotalZAR] =  React.useState(0);
  const[totalUSDT, setTotalUSDT] =  React.useState(0);
  const[totalBTC, setTotalBTC] =  React.useState(0);
  const[filterButtonChecked, setFilterButtonChecked] =  React.useState(false);

  
  const[dateFrom, setDateFrom] =  React.useState(null);
  const[dateTo, setDateTo] =  React.useState(null);
  const [currencyDisplay, setCurrencyDisplay] = useState({
    '1': 'USDT',
    '2': 'ZAR',
    '3': 'BTC',
})
const [countryCurrencyDisplay, setCountryDisplay] = useState('ZAR')

  React.useEffect(()=>{
  
      if(user)
      {
    
        getUserDetails(user.userid)
          setName(user.name + ' ' + user.lastname)
          setUserid(user.userid)
          let newDate = new Date()
          let date = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          let newstring = ''
          if(month<10)
              newstring = '0' + month
          else
              newstring = month

          setUseDate(year + '-' + newstring + '-' + date)

          getPdfDownloadInformation(user.userid)

      }

    },[user])

    const getUserDetails = async (userid) => {
      var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            var mainString = res.data.info.country
            if(mainString && mainString.includes("Namibia"))
            {
              setCurrencyDisplay({
                '1': 'USDT',
                '2': 'NAD',
                '3': 'BTC',
            })
            setCountryDisplay('NAD')
            }else{
           
            }
          }
        });
      }

    const getPdfDownloadInformation = async(userUserid) => {
      var userObj = {
        userid: userUserid,
        dateFrom:dateFrom,
        dateTo: dateTo
      }

      getUserBalances(userUserid)

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-transactions-report`,userObj).then((res)=>{
        if(res.data.status == 200)
        {
         
          setTransactions(res.data.transactions)
        }
      })

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/sum-of-user-products`,userObj).then((res)=>{
        if(res.data.status == 200)
        {
          setUsdtProductAmount(res.data.usdt)
          setBTCProductAmount(res.data.btc)
        }
      })

    }

    const getUserBalances = async(userid) => {
      var userObj = {
          userid: userid,
          dateFrom: dateFrom,
          dateTo: dateTo
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance-report`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
        
            setTotalZAR(res.data.currencyInfo[1].balance)
            setTotalUSDT(res.data.currencyInfo[0].balance)
            setTotalBTC(res.data.currencyInfo[2].balance)
          }
        })

        return true;
    }

  return (
    <div className='mt-8'>
          

    <PDFDownloadLink document={<PDFTransactions title="Transaction Report" name={name} currency={currencyDisplay} countryCurrencyDisplay={countryCurrencyDisplay}  info={transactions} userid={userid} date={useDate} totalUSDT={totalUSDT} totalBTC={totalBTC} totalZAR={totalZAR} applyFilter={filterButtonChecked} dateFrom={dateFrom} dateTo={dateTo} usdtProductTotal={usdtProductAmount} btcProductTotal={btcProductAmount} />} fileName={"Transactions-" + useDate} >
                    {({loading }) => (loading ? "Loading..." : <button  className="btn btn-primary form-control text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Download Full Transaction Report
                </button>)}
                </PDFDownloadLink>

</div>
  )
}

export default UserTransactionsReport