import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
  } from "../Dashboard/LatestTranactionCol";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserSingleTable from "pages/Dashboard/UserSingleTable";
import InvestmentTable from "./InvestmentTable";
function TransactionTracking() {
    
    const[investmentData, setInvestmentData] =  React.useState([]);
    const{auth,user} = React.useContext(UserContext)
    const[title, setTitle] =  React.useState('Transaction Tracking');

    const[transactions, setTransactions] =  React.useState([]);
    


    let { userid } = useParams()

    React.useEffect( () => {
  
        if (auth != null) {
          if (!auth) {
            window.location.href="/login"
          }else{
     
            getUserDeposits()
    
          }
        }
      }, [auth])

    //   const investmentData = {
    //     "C1A0000002": {
    //         "deposits": {
    //             "2": 700000
    //         },
    //         "withdrawals": {
    //             "1": -71502.28,
    //             "2": -1883052.59
    //         },
    //         "productSales": {},
    //         "transactionDeposits": {
    //             "2": 700000
    //         },
    //         "transactionWithdrawals": {
    //             "1": -75595.934,
    //             "2": -1883052.59
    //         },
    //         "userInfo": {
    //             "name": "C1 Holdings (Pty) Ltd "
    //         }
    //     }
    //   };
    

      const getUserDeposits = async () => {
        
        await axios.get(process.env.REACT_APP_BASE + "api/find-all-user-investmenttransactions").then(async (res)=>{
            console.log(res.data)
            if(res.data.status === 200)
                setInvestmentData(res.data.userInvestments)
            else
                setInvestmentData([])
          
        });
      }

  return (
    <React.Fragment>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="dark"
  />
<div className="page-content">
<Container fluid>
{/* Render Breadcrumbs */}
<Breadcrumbs title="Investment Tracking" breadcrumbItem="Investment Tracking" />

<Row>
  <Col md="12" sm="12">
  <InvestmentTable investmentData={investmentData} />
        </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TransactionTracking

