import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

  const FilterTable = ({users}) => {
  const dispatch = useDispatch();

  const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }));

  useEffect(() => {
    console.log("in filter table user is: ", users)
    dispatch(onGetOrders());
  }, [dispatch]);

  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

 
  const columns = useMemo(
    () => [
      {
        Header: "Userid",
        accessor: "userid",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Username",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Lastname",
        accessor: "lastname",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Cell",
        accessor: "cell",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "status",
        accessor: "u_status",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <p>{cellProps.value === 'P' ? 'Pending' : cellProps.value === 'A' ? 'Active' : cellProps.value === 'L' ? 'Confirmation Required' : 'Locked'}</p>;
        },
      },
      {
        Header: "KYC",
        accessor: "fica_status",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
            return <p>{cellProps.value === 'P' ? 'Pending' : cellProps.value === 'A' ? 'Active' : cellProps.value === 'N' ? 'Required' : '---'}</p>;
        },
      },
      {
        Header: "Referral",
        accessor: "referralInfo",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { name, lastname } = cellProps.value || {};
          return (
            <>
              <span>
                {name} {lastname}
              </span>
            </>
          );
        },
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        disableFilters: true,
        Cell: cellProps => {
            return <Date {...cellProps} />;
        },
      },
      {
        Header: "View UserInfo",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          // return <PaymentMethod {...cellProps} />;
                    return (
            <Button className="btn btn-round btn-success" onClick={(e) => {editUserBtn(cellProps.value)}} >
              View User
            </Button>
          );
        }
      },
    ],
    []
  );


  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders();
    }
  }, [onGetOrders, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const editUserBtn = async(userid) => {
    console.log("Clicked Button " + userid)
    window.location.href="/edit-user/" + userid
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">All Users</div>
          <TableContainer
            columns={columns}
            data={users}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FilterTable




