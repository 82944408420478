// UserAdminContext.js
import React, { createContext, useState, useEffect } from 'react';

export const UserAdminContext = createContext();

export const UserProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionToken, setSessionToken] = useState('');

    useEffect(() => {
        // Check if there's a session token stored in localStorage
        const storedSessionToken = localStorage.getItem('AdminC1WSession');
        if (storedSessionToken) {
            setIsLoggedIn(true);
            setSessionToken(storedSessionToken);
        }
    }, []); // Run only once when the component mounts

    const handleLogin = (token) => {
        console.log("setting it true to token: " + token);
        setIsLoggedIn(true);
        setSessionToken(token);
        // Store the session token in localStorage
        localStorage.setItem('AdminC1WSession', token);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setSessionToken('');
        // Remove the session token from localStorage
        localStorage.removeItem('AdminC1WSession');
    };

    return (
        <UserAdminContext.Provider value={{ isLoggedIn, handleLogin, handleLogout, sessionToken }}>
            {children}
        </UserAdminContext.Provider>
    );
};
